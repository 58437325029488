import React, { useState, useEffect } from "react";
import LoaderCard from "../../../LoaderCard/LoaderCard";
import ConfirmCard from "../../../LoaderCard/ConfirmCard";
import ErrorCard from "../../../LoaderCard/ErrorCard";
import dataVal from "../../../../data/Abis.json";
import plus from "../../../../images/plusss.svg";
import PoolData from "../../PoolData";
import { FaRegPaste } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  Input,
  InputGroup,
  InputGroupText,
  Spinner
} from "reactstrap";
import { invokeservice } from "../../../../api/apiService";

export default function LiquidityAddWidget(props) {
  let navigate = useNavigate();
  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const datatok = [
    {
      id: 1,
      label: "BUSD",
      icon: "busdicon",
      tokencontract: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    {
      id: 2,
      label: "EGOLD (V1)",
      icon: "egoldicon",
      tokencontract: "0x8005D97E993668a528008d16338B42f9e976ED0F",
    },
  ];
  const EGOLD_ADDRESS = dataVal.egoldtokencontract;
  const BUSD_ADDRESS = dataVal.busdtokencontract;
  const [addBusdamnt, setaddBusdamnt] = useState(null);
  const [addEgoldamnt, setaddEgoldamnt] = useState(null);
  const [busdBalance, setBusdBalance] = useState("...");
  const [egoldBalance, setEgoldBalance] = useState("...");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");
  const [tok1approve, setTok1approve] = useState(false);
  const [tok2approve, setTok2approve] = useState(false);
  const [swapBtn, setswapBtn] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("acct")) {
      getSummary();
    } else {
      console.log("inactive");
      setaddBusdamnt("");
      setaddEgoldamnt("");
      setBusdBalance(0.0);
      setEgoldBalance(0);
      getSummary();
    }
  }, []);

  const getSummary = async () => {
    const web3 = await new Web3(process.env.REACT_APP_RPC);
    const busdtokencontractInstance = await new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_BUSD_ADDR
    );
    const egoldcontractInstance = new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_EGOLD_ADDR
    );

    if (localStorage.getItem("acct")) {
      await egoldcontractInstance.methods
        .balanceOf(localStorage.getItem("acct"))
        .call({}, function (e, res) {
          console.log("egold -- ", res);
          setEgoldBalance(noround(web3.utils.fromWei(res), 4));
        });
      await busdtokencontractInstance.methods
        .balanceOf(localStorage.getItem("acct"))
        .call({}, function (e, res) {
          console.log("BALANCE BUSD", web3.utils.fromWei(res));
          setBusdBalance(noround(parseFloat(web3.utils.fromWei(res)), 2));
        });
    }
  };

  const getOnchangeval = async (e) => {
    setaddBusdamnt(e);
    if (e > 0) {
      const web3 = await new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        dataVal.pcfactoryabi,
        process.env.REACT_APP_PANCAKE_FACTORY_ADDR
      );
      await pancakeFactorycontractInstance.methods
        .getPair(
          process.env.REACT_APP_BUSD_ADDR,
          process.env.REACT_APP_EGOLD_ADDR
        )
        .call({}, function (err, res) {
          const liquidityInstance = new web3.eth.Contract(
            dataVal.liquidityabi,
            res
          );
          liquidityInstance.methods
            .getReserves()
            .call({}, async function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              }
              if (res) {
                setaddEgoldamnt(
                  parseFloat(
                    e /
                    (web3.utils.fromWei(res[1]) / web3.utils.fromWei(res[0]))
                  ).toFixed(4)
                );
              }
            });
        });
    } else {
      setaddEgoldamnt(0.0);
    }
  };

  const getOnchangevalT = async (e) => {
    setaddEgoldamnt(e);
    if (e > 0) {
      const web3 = await new Web3(process.env.REACT_APP_RPC);

      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        dataVal.pcfactoryabi,
        process.env.REACT_APP_PANCAKE_FACTORY_ADDR
      );
      await pancakeFactorycontractInstance.methods
        .getPair(
          process.env.REACT_APP_EGOLD_ADDR,
          process.env.REACT_APP_BUSD_ADDR
        )
        .call({}, function (err, res) {
          const liquidityInstance = new web3.eth.Contract(
            dataVal.liquidityabi,
            res
          );
          liquidityInstance.methods
            .getReserves()
            .call({}, async function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              }
              if (res) {
                setaddBusdamnt(
                  parseFloat(
                    e /
                    (web3.utils.fromWei(res[0]) / web3.utils.fromWei(res[1]))
                  ).toFixed(4)
                );
              }
            });
        });
    } else {
      setaddBusdamnt(0.0);
    }
  };

  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." || err.message.includes("while converting number to string, invalid number value ")
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }


  const addLiquidityToken = async () => {
    setprocessState({ state: "...", data: null });
    if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
      if (addBusdamnt && addEgoldamnt != null) {
        if (addBusdamnt <= busdBalance && addEgoldamnt <= egoldBalance) {
          setswapBtn(false)
          var today = new Date();
          var futureDate = new Date(today.getTime() + 60000);
          let busdMin = addBusdamnt * 0.95;
          let egoldMin = addEgoldamnt * 0.95;
          try {
            setprocessState({ state: "processing", data: null });
            const token = localStorage.getItem("accessToken");
            const addLQData = {
              job: {
                type: "addLiquidity",
                params: {
                  useragent: navigator.userAgent,
                  tokenA: BUSD_ADDRESS,
                  tokenB: EGOLD_ADDRESS,
                  tokenAamnt: addBusdamnt,
                  tokenBamnt: addEgoldamnt,
                  tokenAmin: 0,
                  tokenBmin: 0,
                  totp:
                    localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
                },
              },
            };
            var headers = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(token),
              },
            };
            invokeservice(addLQData, headers).then((json) => {
              setOTP("")
              if (json.data.status != "failed") {
                setswapBtn(true);
                getSummary();
                setprocessState({ state: "done", data: json.data.task_id });
              } else {
                setswapBtn(true);
                setprocessState({
                  state: "error",
                  data: json.data.message
                });

              }
            });
          } catch (err) {
            checkerror(err)
          }
        } else {
          setswapBtn(true);
          setprocessState({
            state: "error",
            data: "Insufficient balance"
          });
        }

      } else {
        setswapBtn(true);
        setprocessState({
          state: "error",
          data: "INVALID AMOUNTS"
        });
      }
    }
  };



  function getMaxF() {
    setaddBusdamnt(busdBalance);
    getOnchangeval(busdBalance);
  }
  function getMaxT() {
    setaddEgoldamnt(egoldBalance);
    getOnchangevalT(egoldBalance);
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      <PoolData from={datatok[0]} to={datatok[1]} />
      <div class="row minertabsec" style={{ marginBottom: "14px" }}>
        <ul
          class="nav nav-pills mb-1 p-3 pt-1 pb-0 liquiditytabs"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation" style={{ width: "50%" }}>
            <button
              class="nav-link typetabbtn  brltb  "
              id="pills-gseries-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-gseries"
              type="button"
              role="tab"
              aria-controls="pills-gseries"
              aria-selected="false"
              onClick={() => {
                navigate("/trade/liquidity");
              }}
            >
              EGOLD (V2)
            </button>
          </li>
          <li class="nav-item" role="presentation" style={{ width: "50%" }}>
            <button
              class="nav-link typetabbtn brrtb active"
              id="pills-sseries-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-sseries"
              type="button"
              role="tab"
              aria-controls="pills-sseries"
              aria-selected="true"
              onClick={() => {
                navigate("/trade/liquidityV1");
              }}
            >
              EGOLD (V1)
            </button>
          </li>
        </ul>
      </div>
      <div class="detailCard secpadding mb20">
        <div class="fromreactangle pr mb20">
          <div class="p15">
            <p class="fs12 mb10">From</p>
            <p class="fromreactp">
              <input
                className="swapinput"
                placeholder="0.00"
                value={addBusdamnt}
                onChange={(e) => getOnchangeval(e.target.value)}
              />{" "}
              <a class="selecttokendrop">
                <span
                  class="float-right fromreactspan"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="busdicon"></span>
                  BUSD{" "}
                </span>
              </a>
            </p>
            <p class="fs12">
              Balance: {busdBalance}{" "}
              <span
                class="float-right yellowtext fs14"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => getMaxF()}
              >
                Use Max
              </span>
            </p>
          </div>
          <div class="updownimgb">
            <img src={plus} alt="" />
          </div>
        </div>
        <div class="fromreactangle mb20">
          <div class="p15">
            <p class="fs12 mb10">To</p>
            <p class="fromreactp">
              <input
                className="swapinput"
                placeholder="0.00"
                value={addEgoldamnt}
                onChange={(e) => getOnchangevalT(e.target.value)}
              />{" "}
              <a class="selecttokendrop">
                <span
                  class="float-right fromreactspan"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="egoldicon"></span>
                  EGOLD (V1){" "}
                </span>
              </a>
            </p>
            <p class="fs12">
              Balance: {egoldBalance}{" "}
              <span
                class="float-right yellowtext fs14"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => getMaxT()}
              >
                Use Max
              </span>
            </p>
          </div>
        </div>
        {
          localStorage.getItem("isoptionaltfa") == "true" ?
            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <div style={{ marginTop: 10 }}>
                <label for="receiver_address" class="form-label">
                  Please enter 2FA Code :
                </label>
                <InputGroup>
                  <Input
                    name="otp"
                    type={"text"}
                    id="otp"
                    value={OTP}
                    onChange={(otp) => {
                      setOTP(otp.target.value);
                    }}
                    style={{
                      borderTop: "0.489247px solid rgb(222, 226, 230)",
                    }}
                    className='passwordbox'
                  />
                  <InputGroupText
                    className={"inputicon"}
                    onClick={async () => {
                      setOTP(await navigator.clipboard.readText());
                    }}
                  >
                    <FaRegPaste color='black' size={22} />
                  </InputGroupText>
                </InputGroup>
              </div>

            </div> : null
        }
        <button
          type="button"
          className="btn-color-primary"
          style={{ cursor: "pointer", width: "100%", border: "0px" }}
          onClick={() => {
            addLiquidityToken();
          }}
          disabled={!swapBtn}
        >

          Add Liquidity
        </button>
        {processState.state == "..." ? (
          ""
        ) : processState.state == "processing" ? (
          <LoaderCard />
        ) : processState.state == "done" ? (
          <ConfirmCard tx={processState.data} />
        ) : (
          <ErrorCard err={processState.data} />
        )}
      </div>
    </>
  );
}
