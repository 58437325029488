import React, { useState, useEffect } from "react";
import dataVal from "../../../data/Abis.json";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import ErrorCard from "../../LoaderCard/ErrorCard";
import { invokeservice } from "../../../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import {
    Input,
    InputGroup,
    InputGroupText
} from "reactstrap"
const Web3 = require("web3");

export default function MinerInfo(props) {
    const web3 = new Web3(process.env.REACT_APP_RPC);
    const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
    const MINING_ADDRESS = process.env.REACT_APP_MINING_ADDR;
    let navigate = useNavigate();

    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });
    const [maxClaimCount, setmaxClaimCount] = useState(10);
    const [minerName, setMinerName] = useState("...");
    const [approveLoader, setapproveLoader] = useState(false);
    const [submitLoader, setsubmitLoader] = useState(false);
    const [minerPrice, setMinerPrice] = useState("...");
    const [minerHash, setMinerHash] = useState("...");
    const [pending, setpending] = useState("...");
    const [tokenapp, setTokenapp] = useState(true); //token approve button enabled
    const [stakePend, setStakePend] = useState(0);
    const [unstakePend, setUnstakePend] = useState(0);
    const [OTP, setOTP] = useState("");


    useEffect(() => {
        getMinerInfo();

    }, []);


    const getMinerInfo = async () => {
        const contractInstance = new web3.eth.Contract(
            dataVal.mregistryabi,
            process.env.REACT_APP_MINER_REGISTRY
        );

        await contractInstance.methods
            .fetchMinerInfo(props.type.mType)
            .call()
            .then((res) => {
                setMinerName(res.name);
                setMinerPrice(parseInt(res.minerBaseRate));
                setMinerHash(parseInt(res.hashRate));
            });
        const farmInstance = new web3.eth.Contract(
            dataVal.farmAbi,
            process.env.REACT_APP_FARMING_ADDR
        );
        await farmInstance.methods
            .pending(0, props.type._id)
            .call({}, async function (e, pending) {
                setpending(web3.utils.fromWei(pending));
            });
    };



    async function mine(mid) {

        setprocessState({ state: "...", data: null });
        try {
            if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
                setsubmitLoader(true);
                setprocessState({ state: "processing", data: null });

                const token = localStorage.getItem("accessToken");
                const useremail = localStorage.getItem("email");
                const stakeData = {
                    job: {
                        type: "StakeMiner",
                        params: {
                            totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
                            useragent: navigator.userAgent,
                            user: useremail,
                            miner_id: mid,
                        },
                    },
                };
                var headers = {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + String(token),
                    },
                };
                invokeservice(stakeData, headers).then((json) => {
                    setsubmitLoader(false);
                    setOTP("")
                    if (json.data.status != "failed") {
                        if (json.data.status != "failed") {
                            var x = [];
                            if (localStorage.getItem("StakePending"))
                                x = JSON.parse(localStorage.getItem("StakePending"));
                            x.push(json.data.task_id);
                            localStorage.setItem("StakePending", JSON.stringify(x));
                            getMinerInfo();
                            props.setpend(x.length)
                            setTokenapp(true);
                            setprocessState({ state: "done", data: json.data.task_id });
                        } else {
                            setprocessState({ state: "error", data: json.data.message });
                        }

                    } else {
                        setprocessState({ state: "error", data: json.data.message });
                    }
                });
            }
        } catch (err) {
            setOTP("")
            setsubmitLoader(false);
            checkerror(err);
        }
    }

    function checkerror(err) {
        if (
            err.message ==
            "Please pass numbers as strings or BN objects to avoid precision errors." ||
            err.message.includes(
                "while converting number to string, invalid number value "
            )
        ) {
            setprocessState({
                state: "error",
                data: "Please provide a valid input",
            });
        } else if (JSON.stringify(err.message).includes("transaction underpriced"))
            setprocessState({
                state: "error",
                data: "Transaction was underpriced. Please try increasing the gas price",
            });
        else
            setprocessState({
                state: "error",
                data: JSON.stringify(err.message),
            });
    }

    return (
        <div>
            <div class="mineraccordian">
                <div class="accordion" id="accordionExample">
                    <div class="mineaccordianall">
                        <div class="mineaccorsec">
                            <div class="minerstate">
                                <div class="mineaccorimg">
                                    {minerName !== "..." ? (
                                        <img
                                            src={require("../../../images/miners/" +
                                                minerName +
                                                ".png")}
                                            alt=""
                                            class="img-fluid"
                                        />
                                    ) : null}
                                </div>
                                <span class="inactivebadge">Inactive</span>
                            </div>
                            <div class="mineaccorbtnsec">
                                <div class="mineaccorbtns mb15">
                                    <a class="mineaccorbtn active">Miner Id: {props.type._id}</a>
                                    <a class="mineaccorbtn">Power: {minerHash} Mhs</a>
                                </div>
                                <div class="mineaccorbtns">
                                    <a class="mineaccorbtn">Miner type: {minerName}</a>
                                    <a class="mineaccorbtn">
                                        Claimed:{" "}
                                        {parseInt(
                                            props.tokendata.data.find(
                                                (x) => x.mid === props.type._id
                                            ) != undefined
                                                ? props.tokendata.data.find(
                                                    (x) => x.mid === props.type._id
                                                ).ctr
                                                : 0
                                        )}
                                        / {maxClaimCount}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            class="accordion-item mineraccordianitem mineaccoritem"
                            style={{ background: "#e0e7ea" }}
                        >
                            <h2 class="accordion-header" id="headingOne">
                                <button
                                    class="accordion-button collapsed mineraccordianbtn minebtn"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#collapseOne" + props.index}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    {" "}
                                    Miner Details{" "}
                                </button>
                            </h2>
                            <div
                                id={"collapseOne" + props.index}
                                class="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <div class="accordion-body mineraccordianbody">
                                    <div class="performancehistroy">
                                        <div>
                                            <p class="minerownep">Mining fuel (Egold)</p>
                                            <p class="minerownep2 redtext">
                                                {/* {pending != "..." ? parseFloat(pending * 0.5).toFixed(4) : "..."}
                         */}
                                                50% of Output
                                            </p>
                                        </div>
                                        <div>
                                            <p class="minerownep">Depreciation (Egold)</p>
                                            <p class="minerownep2 redtext">
                                                {pending != "..." &&
                                                    props.tokendata.data.find(
                                                        (x) => x.mid === props.type._id
                                                    ) != undefined
                                                    ? parseInt(
                                                        props.tokendata.data.find(
                                                            (x) => x.mid === props.type._id
                                                        ).ctr
                                                    ) == 0
                                                        ? "0"
                                                        : (Number(
                                                            props.tokendata.data.find(
                                                                (x) => x.mid === props.type._id
                                                            ).ctr
                                                        ) +
                                                            1) *
                                                        5 -
                                                        5
                                                    : 0}
                                                % Of Output
                                            </p>
                                        </div>
                                        <div>
                                            <p class="minerownep">Hosting (Egold) </p>
                                            <p class="minerownep2 redtext">
                                                {/* - {pending != "..." ? parseFloat(pending * 0.01).toFixed(4) : "..."} EGOLD */}
                                                1% of Output
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            padding: "5px 0px",
                                            background: "#c1cfd5",
                                            borderRadius: "0px 0px 7px 7px",
                                        }}
                                    >
                                        {
                                            localStorage.getItem("isoptionaltfa") == "true" ?
                                                <div style={{ marginTop: 20, marginBottom: 30 }}>
                                                    <div style={{ marginTop: 10, padding: "0px 20px" }}>
                                                        <label for="receiver_address" class="form-label">
                                                            Please enter 2FA Code :
                                                        </label>
                                                        <InputGroup>
                                                            <Input
                                                                name="otp"
                                                                type={"text"}
                                                                id="otp"
                                                                value={OTP}
                                                                onChange={(otp) => {
                                                                    setOTP(otp.target.value);
                                                                }}
                                                                style={{
                                                                    borderTop: "0.489247px solid rgb(222, 226, 230)",
                                                                }}
                                                                className='passwordbox'
                                                            />
                                                            <InputGroupText
                                                                className={"inputicon"}
                                                                onClick={async () => {
                                                                    setOTP(await navigator.clipboard.readText());
                                                                }}
                                                            >
                                                                <FaRegPaste color='black' size={22} />
                                                            </InputGroupText>
                                                        </InputGroup>
                                                    </div>

                                                </div> : null
                                        }
                                        <div
                                            class="selectcurbtnsec appactiveminer"
                                            style={{ paddingBottom: "0px" }}
                                        >
                                            <button
                                                class="btn-color-primary"
                                                style={{ cursor: "pointer", border: "0px", width: "100%" }}
                                                onClick={(e) => { mine(props.type._id) }}
                                            >
                                                {submitLoader ? (
                                                    <Spinner
                                                        color="black"
                                                        style={{ height: 16, width: 16 }}
                                                    />
                                                ) : (
                                                    "Activate miner"
                                                )}
                                            </button>
                                        </div>
                                        <div
                                            class="selectcurbtnsec appactiveminer "
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                navigate("/transfer/" + props.type._id);
                                            }}
                                        >
                                            <a class="btn-color-primary w-100">Transfer Miner</a>
                                        </div>
                                    </div>

                                    {processState.state == "..." ? (
                                        ""
                                    ) : processState.state == "processing" ? (
                                        <div style={{ margin: "20px" }}>
                                            <LoaderCard />
                                        </div>
                                    ) : processState.state == "done" ? (
                                        <div style={{ margin: "20px" }}>
                                            <ConfirmCard tx={processState.data} />
                                        </div>
                                    ) : (
                                        <div style={{ margin: "20px" }}>
                                            <ErrorCard err={processState.data} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
