import React, { useState, useEffect, useRef } from "react";
import dataVal from "../../../data/Abis.json";
import { Spinner } from "reactstrap";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import ErrorCard from "../../LoaderCard/ErrorCard";
import { FaRegPaste } from "react-icons/fa6";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { invokeservice, gettaskid } from "../../../api/apiService";
const Web3 = require("web3");

export default function Active(props) {
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const [approveLoader, setapproveLoader] = useState(false);
  const [egoldBalance, setEgoldBalance] = useState("...");
  const [loaderBTN, setloaderBTN] = useState(false);
  const [stakePend, setStakePend] = useState(0);
  const [unstakePend, setUnstakePend] = useState(0);
  const [currentblock, setCurrentBlock] = useState(0);
  const [maxClaimCount, setmaxClaimCount] = useState(10);
  const [tokenapp, setTokenapp] = useState(true);
  const [OTP, setOTP] = useState("");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });

  useEffect(() => {
    getSummary();
  }, []);

  async function getSummary() {
    const currentbk = await web3.eth.getBlockNumber();
    setCurrentBlock(currentbk);
    const egoldcontractInstance = new web3.eth.Contract(
      dataVal.busdtokenabi,
      dataVal.egoldtokencontract
    );
    await egoldcontractInstance.methods
      .balanceOf(localStorage.getItem("acct"))
      .call({}, function (e, res) {
        setEgoldBalance(noround(parseFloat(web3.utils.fromWei(res)), 5));
      });
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  async function unstake(mid) {
    setprocessState({ state: "...", data: null });
    console.log("jereeeee");
    if (
      Number(
        parseFloat(
          0.01 *
            (props.tokendata.data.find((x) => x.mid === props.type._id) !=
            undefined
              ? props.tokendata.data.find((x) => x.mid === props.type._id).pend
              : 0) +
            (props.tokendata.data.find((x) => x.mid === props.type._id) !=
            undefined
              ? props.tokendata.data.find((x) => x.mid === props.type._id)
                  .pend * 0.5
              : 0)
        ).toFixed(5)
      ) >= parseFloat(egoldBalance)
    ) {
      setprocessState({
        state: "error",
        data: (
          <p>
            You have an insufficient amount of EGOLD to process this claim{" "}
            <br />
            Recommended amount of EGOLD to have :{" "}
            {0.01 *
              (props.tokendata.data.find((x) => x.mid === props.type._id) !=
              undefined
                ? props.tokendata.data.find((x) => x.mid === props.type._id)
                    .pend
                : 0) +
              (props.tokendata.data.find((x) => x.mid === props.type._id) !=
              undefined
                ? props.tokendata.data.find((x) => x.mid === props.type._id)
                    .pend * 0.5
                : 0)}{" "}
            EGOLD
          </p>
        ),
      });
    } else {
      try {
        if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
          setprocessState({ state: "processing", data: null });
          const token = localStorage.getItem("accessToken");
          const useremail = localStorage.getItem("email");
          const unstakeData = {
            job: {
              type: "UnstakeMiner",
              params: {
                useragent: navigator.userAgent,
                user: useremail,
                miner_id: mid,
                totp:
                  localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
              },
            },
          };
          var headers = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(token),
            },
          };
          invokeservice(unstakeData, headers).then((json) => {
            setOTP("");
            if (!json.data.status) {
              var x = [];
              if (localStorage.getItem("UnstakePending"))
                x = JSON.parse(localStorage.getItem("UnstakePending"));
              x.push(json.data.task_id);
              localStorage.setItem("UnstakePending", JSON.stringify(x));
              props.setunstakepend(x.length);
              setloaderBTN(false);
              setprocessState({
                state: "done",
                data: json.data.task_id,
              });
            } else {
              setOTP("");
              setloaderBTN(false);
              console.log("error-", json.data.message);
              checkerror(json.data.message);
            }
          });
        }
      } catch (err) {
        checkerror(err);
        console.log(err);
        setOTP("");
      }
    }
  }

  function checkerror(err) {
    if (
      err.message ==
        "Please pass numbers as strings or BN objects to avoid precision errors." ||
      err.message.includes(
        "while converting number to string, invalid number value "
      )
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }

  return (
    <div>
      <div class="mineraccordian">
        <div class="accordion" id="accordionExample">
          <div class="mineaccordianall">
            <div class="mineaccorsec">
              <div class="minerstate">
                <div class="mineaccorimg">
                  {props.tokendata.data.find((x) => x.mid === props.type._id) !=
                  undefined ? (
                    <img
                      src={require("../../../images/miners/" +
                        props.tokendata.data.find(
                          (x) => x.mid === props.type._id
                        ).mtype +
                        ".png")}
                      alt=""
                      class="img-fluid"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <span class="activebadge">Active</span>
              </div>
              <div class="mineaccorbtnsec">
                <div class="mineaccorbtns mb15">
                  <a class="mineaccorbtn active">Miner Id: {props.type._id}</a>

                  <a class="mineaccorbtn">
                    Power:{" "}
                    {props.tokendata.data.find(
                      (x) => x.mid === props.type._id
                    ) != undefined
                      ? props.tokendata.data.find(
                          (x) => x.mid === props.type._id
                        ).mhash
                      : "..."}{" "}
                    Mhs
                  </a>
                </div>
                <div class="mineaccorbtns">
                  <a class="mineaccorbtn">
                    Miner type:
                    {props.tokendata.data.find(
                      (x) => x.mid === props.type._id
                    ) != undefined
                      ? props.tokendata.data.find(
                          (x) => x.mid === props.type._id
                        ).mtype
                      : "..."}
                  </a>
                  <a class="mineaccorbtn">
                    Claimed:{" "}
                    {Number(
                      props.tokendata.data.find(
                        (x) => x.mid === props.type._id
                      ) != undefined
                        ? props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ).ctr
                        : 0
                    )}
                    /{maxClaimCount}
                  </a>
                </div>
              </div>
            </div>
            <div class="accordion-item mineraccordianitem mineaccoritem">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed mineraccordianbtn minebtn activeminerbtn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapseFour" + props.index}
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  <div>
                    Net Claim:{" "}
                    <span class="greentext">
                      {parseFloat(
                        (parseInt(
                          100 -
                            (51 +
                              (Number(
                                props.tokendata.data.find(
                                  (x) => x.mid === props.type._id
                                ) != undefined
                                  ? props.tokendata.data.find(
                                      (x) => x.mid === props.type._id
                                    ).ctr
                                  : 0
                              ) +
                                1) *
                                5 -
                              5)
                        ) /
                          100) *
                          parseFloat(
                            props.tokendata.data.find(
                              (x) => x.mid === props.type._id
                            ) != undefined
                              ? props.tokendata.data.find(
                                  (x) => x.mid === props.type._id
                                ).pend
                              : 0
                          )
                      ).toFixed(5)}{" "}
                      EGOLD (V1)
                    </span>
                  </div>
                  <p class="activeminertext">Details</p>
                </button>
              </h2>
              <div
                id={"collapseFour" + props.index}
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body mineraccordianbody">
                  <div class="performancehistroy pb-0">
                    <div>
                      <p class="minerownep">Mined (Egold)</p>
                      <p class="minerownep2">
                        {props.tokendata.data.find(
                          (x) => x.mid === props.type._id
                        ) != undefined
                          ? parseFloat(
                              props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ).pend
                            ).toFixed(4)
                          : "..."}
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">Start block </p>
                      <p class="minerownep2 bluetext">
                        {" "}
                        <a
                          target="_blank"
                          style={{ color: "unset" }}
                          href={"https://bscscan.com/block/" + props.type.block}
                        >
                          {props.type.block}
                        </a>
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">Blocks mined </p>
                      <p class="minerownep2">
                        {" "}
                        {currentblock - props.type.block}
                      </p>
                    </div>
                  </div>
                  <div class="performancehistroy">
                    <div>
                      <p class="minerownep">Mining fuel (50%)</p>
                      <p class="minerownep2 redtext">
                        {parseFloat(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ).pend * 0.5
                            : 0
                        ).toFixed(4)}{" "}
                        EGOLD
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">
                        Depreciation (
                        {Number(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ).ctr
                            : 0
                        ) == 0
                          ? "0"
                          : (Number(
                              props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ) != undefined
                                ? props.tokendata.data.find(
                                    (x) => x.mid === props.type._id
                                  ).ctr
                                : 0
                            ) +
                              1) *
                              5 -
                            5}
                        %)
                      </p>
                      <p class="minerownep2 redtext">
                        {parseFloat(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ).pend *
                                (((Number(
                                  props.tokendata.data.find(
                                    (x) => x.mid === props.type._id
                                  ) != undefined
                                    ? props.tokendata.data.find(
                                        (x) => x.mid === props.type._id
                                      ).ctr
                                    : 0
                                ) +
                                  1) *
                                  5 -
                                  5) /
                                  100)
                            : 0
                        ).toFixed(4)}{" "}
                        EGOLD
                      </p>
                    </div>
                    <div>
                      <p class="minerownep">Hosting (1%) </p>
                      <p class="minerownep2 redtext">
                        {parseFloat(
                          props.tokendata.data.find(
                            (x) => x.mid === props.type._id
                          ) != undefined
                            ? props.tokendata.data.find(
                                (x) => x.mid === props.type._id
                              ).pend * 0.01
                            : 0
                        ).toFixed(4)}{" "}
                        EGOLD
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "5px 0px",
                      background: "#c1cfd5",
                      borderRadius: "0px 0px 7px 7px",
                    }}
                  >
                    {localStorage.getItem("isoptionaltfa") == "true" ? (
                      <div style={{ marginTop: 20, marginBottom: 30 }}>
                        <div style={{ marginTop: 10, padding: "0px 20px" }}>
                          <label for="receiver_address" class="form-label">
                            Please enter 2FA Code :
                          </label>
                          <InputGroup>
                            <Input
                              name="otp"
                              type={"text"}
                              id="otp"
                              value={OTP}
                              onChange={(otp) => {
                                setOTP(otp.target.value);
                              }}
                              style={{
                                borderTop:
                                  "0.489247px solid rgb(222, 226, 230)",
                              }}
                              className="passwordbox"
                            />
                            <InputGroupText
                              className={"inputicon"}
                              onClick={async () => {
                                setOTP(await navigator.clipboard.readText());
                              }}
                            >
                              <FaRegPaste color="black" size={22} />
                            </InputGroupText>
                          </InputGroup>
                        </div>
                      </div>
                    ) : null}
                    <div class="selectcurbtnsec appactiveminer">
                      <button
                        className="btn-color-primary"
                        // class={
                        //   tokenapp
                        //     ? "btn-color-primary btn-disabled"
                        //     : "btn-color-primary"
                        // }
                        style={{
                          cursor: "pointer",
                          border: "unset",
                          width: "100%",
                        }}
                        onClick={(e) => unstake(props.type._id)}
                      >
                        {loaderBTN ? (
                          <Spinner
                            color="black"
                            style={{ height: 16, width: 16 }}
                          />
                        ) : (
                          "Collect Egold (V1)"
                        )}
                      </button>
                    </div>
                    {processState.state == "..." ? (
                      ""
                    ) : processState.state == "processing" ? (
                      <div style={{ margin: "20px" }}>
                        <LoaderCard />
                      </div>
                    ) : processState.state == "done" ? (
                      <div style={{ margin: "20px" }}>
                        <ConfirmCard tx={processState.data} />
                      </div>
                    ) : (
                      <div style={{ margin: "20px" }}>
                        <ErrorCard err={processState.data} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
