import React, { useEffect, useState } from "react";
import PillsMenu from "./Menu/PillsMenu";
import TradePillsMenu from "./Trade/TradePillsMenu";
import LPAddWidget from "./Trade/LPfarm/LPAddWidget";
import LPCollectWidget from "./Trade/LPfarm/LPCollectWidget";
import LPCard from "./Trade/LPfarm/LPCard";
import LPFarmData from "./Trade/LPfarm/LPFarmData";


export default function Farm() {
  const miners = [5, 6, 7, 8, 9, 2, 3, 4];
  return (
    <>
      <div
        class="mainsection"
        style={{
          position: "relative",
        }}
      >
        <div class="minertabsec secpadding lightgrey-bg brfull">
          <PillsMenu activeval="trade" />
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-trade" role="tabpanel" aria-labelledby="pills-trade-tab" tabindex="0">
              <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
                <TradePillsMenu activeval="lpfarm" />
                <div class="tab-content" id="pills-tabContent">

                  <div class="tab-pane fade show active" id="pills-lpfarm" role="tabpanel" aria-labelledby="pills-lpfarm-tab" tabindex="0">
                    <LPAddWidget />
                    <LPCollectWidget />
                    <LPCard />
                    <LPFarmData />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
