import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Spinner } from "reactstrap";
import dataVal from "../../data/Abis.json";

const TotaleSupply = () => {
  const Web3 = require("web3");
  const FARMING_ABI = dataVal.farmAbi;
  const PANCAKE_FACTORY_ABI = dataVal.pcfactoryabi;
  const LIQUIDITY_ABI = dataVal.liquidityabi;
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;
  const EGOLD_ADDRESS = process.env.REACT_APP_EGOLD_ADDR;
  const EGOLDV2_ADDRESS = dataVal.egoldv2tokencontract;
  const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDR;
  const USDT_ADDRESS = dataVal.usdttokencontract;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;
  const BUSDFARM_ABI = dataVal.lpfarmabi;

  const [data, setData] = useState([]);
  const [mintedtotal, setMintedTotal] = useState("...");
  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });
  const [liquidityEGOLDBlnce, setliquidityEGOLDBlnce] = useState("...");
  const [liquidityEGOLDv2Blnce, setliquidityEGOLDv2Blnce] = useState("...");
  const [staketotal, setStakeTotal] = useState("...");
  const [farmyield, setFarmYield] = useState("...");
  const [salerburn, setSalerburn] = useState({ data: "...", loading: true });

  const web3 = new Web3(process.env.REACT_APP_RPC);
  const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);
  const pancakeFactorycontractInstance = new web3.eth.Contract(
    PANCAKE_FACTORY_ABI,
    PANCAKE_FACTORY_ADDRESS
  );

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";
    const totalstakeurl =
      "https://egold-staking-transactions.herokuapp.com/totalegoldstaked";
      const salerburn = "https://auth.egoldfarm.com/totalegburned";
  
      //fetch sales burn
      fetch(salerburn)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          setSalerburn({ data: newData.total_egold_burned, loading: false });
        })
        .catch(function (error) {
          console.log(error);
        });

    await pancakeFactorycontractInstance.methods
      .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
      .call({}, function (e, res) {
        console.log("pair add-",res)
        const LQInstance = new web3.eth.Contract(LIQUIDITY_ABI, res);
        console.log("RESPONSE LQ ", LQInstance);
        LQInstance.methods.getReserves().call({}, function (e, reserve) {
          console.log("reeserves",reserve);
          setliquidityEGOLDBlnce(
            parseFloat(web3.utils.fromWei(reserve[0])).toFixed(3)
          );
        });
      });
    await pancakeFactorycontractInstance.methods
      .getPair(USDT_ADDRESS, EGOLDV2_ADDRESS)
      .call({}, function (e, res) {
        console.log("pair2 add-",res)
        const LQInstance = new web3.eth.Contract(LIQUIDITY_ABI, res);
        console.log("RESPONSE LQ ", LQInstance);
        LQInstance.methods.getReserves().call({}, function (e, reserve) {
          console.log("reeserves2", reserve);
          setliquidityEGOLDv2Blnce(
            parseFloat(web3.utils.fromWei(reserve[1])).toFixed(3)
          );
        });
      });

    const depositLPTInstance = await new web3.eth.Contract(
      BUSDFARM_ABI,
      BUSD_EGOLD_FARM_ADDRESS
    );

    await depositLPTInstance.methods
      .paidOut()
      .call({}, async function (e, res) {
        console.log("farmyeild-",parseFloat(web3.utils.fromWei(res)));
        setFarmYield(parseFloat(web3.utils.fromWei(res)));
      });

    //fetch total stake
    fetch(totalstakeurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setStakeTotal(newData.totalStaked);
      })
      .catch(function (error) {
        console.log(error);
      });

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    await farmInstance.methods.paidOut().call({}, async function (e, res) {
      setMintedTotal(
        parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
      );
    });
  };

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 16}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "18px",
          }}
        >
          Active Supply
        </text>
        <text
          x={centerX}
          y={centerY + 16}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {numFormatter(
            farmyield +
              15000 +
              (mintedtotal - miningStats.data.egoldSupply.burnt)-
              noround(salerburn.data, 3)
          )}
        </text>
      </>
    );
  };

  useEffect(() => {
    if (
      mintedtotal !== "..." &&
      miningStats.data !== "..." &&
      liquidityEGOLDBlnce !== "..." &&
      liquidityEGOLDv2Blnce !== "..." &&
      staketotal !== "..." &&
      farmyield !== "..."
    ) {
      setData([
        {
          id: "Locked(Liquidity)",
          label: "Locked in Liquidity",
          value: noround(parseFloat(liquidityEGOLDBlnce) + parseFloat(liquidityEGOLDv2Blnce), 2),
          color: "#FFE18C",
        },
        {
          id: "Locked(Staking)",
          label: "Locked for Staking",
          value: noround(staketotal, 2),
          color: "#FFC727",
        },
        {
          id: "Held By User",
          label: "Held By User",
          value: noround(
            farmyield +
              15000 +
              (mintedtotal - miningStats.data.egoldSupply.burnt) -
              liquidityEGOLDBlnce -
              liquidityEGOLDv2Blnce -
              staketotal,
            2
          ),
          color: "#4F6B75",
        },
      ]);
    }
  }, [mintedtotal, farmyield, miningStats, staketotal, liquidityEGOLDBlnce]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {mintedtotal == "..." ||
      farmyield == "..." ||
      miningStats.data == "..." ||
      staketotal == "..." ||
      liquidityEGOLDBlnce == "..." ||
      liquidityEGOLDv2Blnce == "..." ||
      salerburn.loading ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={
                window.innerWidth < 500
                  ? { top: 20, right: 20, bottom: 0, left: 20 }
                  : { top: 40, right: 80, bottom: 40, left: 80 }
              }
              valueFormat=" >-,"
              startAngle={-80}
              sortByValue={false}
              activeOuterRadiusOffset={8}
              enableArcLinkLabels={window.innerWidth < 500 ? false : true}
              enableArcLabels={false}
              arcLinkLabel={(e) => `${e.id}\n(${e.value})`}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="#ffffff"
              innerRadius={0.45}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={(slice) => slice.data.color}
            />
          </div>
          <div class="miningcapacity" id="miningCapacitySection">
            <div class="detailCard secpadding mb20">
              <div class="bardelshowp mb10">
                Active EGOLD Supply :{" "}
                {parseFloat(
                  noround(
                    farmyield +
                      15000 +
                      (mintedtotal - miningStats.data.egoldSupply.burnt) -
                      noround(salerburn.data, 3),
                    2
                  )
                ).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="otherbardot"></div>Locked in Liquidity :{" "}
                {parseFloat(
                  noround(parseFloat(liquidityEGOLDBlnce) + parseFloat(liquidityEGOLDv2Blnce), 2)
                ).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="primarybardot"></div>
                Locked for Staking :{" "}
                {parseFloat(noround(staketotal, 2)).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="secondarybardot"></div>
                Held By User :{" "}
                {parseFloat(
                  noround(
                    farmyield +
                      15000 +
                      (mintedtotal - miningStats.data.egoldSupply.burnt) -
                      noround(salerburn.data, 3) -
                      parseFloat(liquidityEGOLDBlnce) -
                      parseFloat(liquidityEGOLDv2Blnce) -
                      staketotal,
                    2
                  )
                ).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
