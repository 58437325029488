import React, { useEffect, useState } from "react";
import PillsMenu from "./Menu/PillsMenu";
import TradePillsMenu from "./Trade/TradePillsMenu";
import TradeData from "./Trade/Swap/TradeData";
import TradeWidget from "./Trade/Swap/TradeWidget";
import ConvertCard from "../Components/EGConverter/ConvertCard"


export default function Swap() {


  return (
    <>
      <div
        class="mainsection"
        style={{
          position: "relative",
        }}
      >
        <div class="minertabsec secpadding lightgrey-bg brfull">
          <PillsMenu activeval="trade" />
          <div>
            {/* <ConvertCard /> */}
          </div>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-trade" role="tabpanel" aria-labelledby="pills-trade-tab" tabindex="0">
              <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
                <TradePillsMenu activeval="swap" />
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-swap" role="tabpanel" aria-labelledby="pills-swap-tab" tabindex="0">
                    <TradeWidget />
                    {/* <TradeData /> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
