import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./NewStyle.css"
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StrictMode } from "react";
import Login from "./Components/Login/Login"
import Footer from "./Components/Footer/Footer"
import ErrorPage from './Components/ErrorPage';
import Header from "./Components/Header/Header";
import HeaderNowallet from "./Components/Header/HeaderNoWallet"
import ForgotPassword from './Components/Login/ForgotPassword';
import ResetPassword from "./Components/Login/ResetPassword";
import EmailSuccess from "./Components/Login/EmailVerification/Success"
import EmailFail from "./Components/Login/EmailVerification/Failure"
import Register from "./Components/Login/Register";
import VerifyMail from "./Components/Login/EmailVerification/verifyMail.jsx"
import ActivateFA from './Components/Login/ActivateFA';
import Wallet from "./Components/Wallet/wallet";
import SelectToken from "./Components/Wallet/SelectToken";
import Send from "./Components/Wallet/send";
import Receive from './Components/Wallet/receive';
import CheckMail from "./Components/Login/EmailVerification/checkMail.jsx"
import Transfer from "./Components/AppTransfer";
import Notifications from "./Components/AppNotifications";
import SelNotification from "./Components/AppNotiSelected";
import ProtectedRoute from './routes/protectedRoutes';
import Changepw from './Components/Login/Changepw';
import AppMiningCalc from "./Components/AppMiningCalc"

// Trade
import Swap from "./Components/AppTradeSwap";
import LiquidityV1 from "./Components/AppTradeLiquidityv1.js";
import LiquidityV2 from "./Components/AppTradeLiquidityv2.js";
import LPFarm from "./Components/AppTradeFarm";

// //Mine
import Inactive from "./Components/Mine/Inactive/Inactive"
import Active from "./Components/Mine/Active/Active"
import Junk from "./Components/Mine/Junkyard/Junkyard"

//STAKE
import StakeLive from "./Components/AppStakeLive";
import StakeCompleted from "./Components/AppStakeCompleted";
import StakeUpcoming from "./Components/AppStakeUpcoming";

import Stats from "./Components/AppStats";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>

        <div style={{ flex: "1 1" }}>
          <Login />
        </div>

        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/changepw",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <HeaderNowallet />
            <Changepw />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,

  },
  {
    path: "/wallet",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Wallet />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/selecttoken",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <SelectToken />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/send",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Send />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/receive",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Receive />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/register",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Register />
        </div>

        <Footer />

      </>
    ),
  },
  {
    path: "/register/:ref",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Register />
        </div>

        <Footer />
      </>
    ),
  },
  {
    path: "/forgotpassword/:query",
    element: (
      <>
        <ResetPassword />
      </>
    ),
  },
  {
    path: "/emailverifysuccess",
    element: (
      <>
        <EmailSuccess />
      </>
    ),
  },
  {
    path: "/enableauth",
    element: (
      <>
        <ActivateFA />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/emailverifyfailure",
    element: (
      <>
        <EmailFail />
      </>
    ),
  },
  {
    path: "/forgotpw",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ForgotPassword />
        </div>

        <Footer />

      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/notifications",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Notifications />
          </ProtectedRoute>
        </div>

        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/selectednotification",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <SelNotification />
          </ProtectedRoute>
        </div>

        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/calculator",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <AppMiningCalc />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Swap />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/swap",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Swap />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/liquidity",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <LiquidityV2 />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/liquidityV1",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <LiquidityV1 />
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/trade/lpfarm",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <LPFarm />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/stake",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <StakeLive />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/stake/live",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <StakeLive />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/stake/completed",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <StakeCompleted />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/stake/upcoming",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <StakeUpcoming />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Inactive />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine/inactive",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Inactive />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine/active",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Active />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/mine/junk",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Junk />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/stats",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Stats />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/transfer/:id",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ProtectedRoute>
            <Header />
            <Transfer />
          </ProtectedRoute>
        </div>
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/failure/:query",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <CheckMail />
        </div>

        <Footer />
      </>
    ),
  },
  {
    path: "/verifymail",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <VerifyMail />
        </div>

        <Footer />
      </>
    ),
  },
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <div className="wrapper">
      <RouterProvider router={router} />
    </div>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
