import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUserCog, FaUserPlus } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import dataVal from "../../../data/Abis.json";

export default function LPCard(props) {

  let navigate = useNavigate();
  const Web3 = require("web3");
  const BUSDFARM_ABI = dataVal.lpfarmabi;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;
  const PANCAKE_FACTORY_ABI = dataVal.pancakeFactoryAbi
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const BUSD_ADDRESS = dataVal.busdtokencontract;
  const EGOLD_ADDRESS = dataVal.egoldtokencontract;
  const TOKEN_ABI = dataVal.busdtokenabi;
  const BUSDFARM_ADDRESS = dataVal.busdfarmcontract;
  const PAIR_ABI = dataVal.lppairabi;



  const [marketData, setmarketData] = useState([]);
  const [egtradeData, setegTradeData] = useState({ data: null, loading: true });
  const [farmData, setfarmData] = useState({ data: null, loading: true });
  const [yieldBlock, setyieldBlock] = useState("0.00");
  const [lptStaked, setlptStaked] = useState("0.00");
  const [busdlpt, setbusdlpt] = useState(null);



  useEffect(() => {

    if (localStorage.getItem("acct")) {
      console.log("active");
      getfarmedInfo();
      latestfarmDetails();
      fetchLQBalance()
      fetchTotalLPT();
    } else {
      console.log("inactive");
      fetchTotalLPT();
      getfarmedInfo();
      latestfarmDetails();
    }
  }, []);

  const fetchLQBalance = async () => {
    if (localStorage.getItem("acct")) {
      const web3 = new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        PANCAKE_FACTORY_ABI,
        PANCAKE_FACTORY_ADDRESS
      );
      await pancakeFactorycontractInstance.methods
        .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
        .call({}, function (e, res) {
          const LQInstance = new web3.eth.Contract(TOKEN_ABI, res);
          LQInstance.methods
            .balanceOf(BUSD_EGOLD_FARM_ADDRESS)
            .call({}, function (e, resLQStake) {
              setlptStaked(
                parseFloat(web3.utils.fromWei(resLQStake)).toFixed(4)
              );
            });
        });
    }
  };

  const latestfarmDetails = () => {
    setfarmData({ loading: true });

    const farmurl =
      "https://egold-marketdata.herokuapp.com/v1/summary/getFarm/20";
    const marketurl = "https://egold-marketdata.herokuapp.com/v1/summary/";
    const egurltrade =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20";
    fetch(egurltrade)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) => setegTradeData({ data: newData, loading: false }))
      .catch(function (error) {
        console.log(error);
      });
    //fetch Farm data
    fetch(farmurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setfarmData({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });

    fetch(marketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData2) {
        setmarketData(newData2);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getfarmedInfo = async () => {
    const webb3 = new Web3(process.env.REACT_APP_RPC);
    const farmedLPTInstance = await new webb3.eth.Contract(
      BUSDFARM_ABI,
      BUSD_EGOLD_FARM_ADDRESS
    );
    await farmedLPTInstance.methods
      .rewardPerBlock()
      .call({}, function (e, resYield) {
        setyieldBlock(webb3.utils.fromWei(resYield));
      });
  };
  const fetchTotalLPT = async () => {
    const w3 = new Web3("https://bsc-dataseed.binance.org/");
    const web3 = new Web3("https://bsc-dataseed.binance.org/");

    //get total lp in farm

    var tokencaddressbusd = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";

    var tokencaddress2 = EGOLD_ADDRESS;
    const factoryInstancew3 = new web3.eth.Contract(
      PANCAKE_FACTORY_ABI,
      PANCAKE_FACTORY_ADDRESS
    );

    let PAIR_ADDRESSbusd;
    await factoryInstancew3.methods
      .getPair(tokencaddressbusd, tokencaddress2)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        PAIR_ADDRESSbusd = res;
      });

    const pairInstancetoo = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESSbusd);
    await pairInstancetoo.methods
      .balanceOf(BUSDFARM_ADDRESS)
      .call({}, async function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        } else {
          console.log("BUSDLPT", web3.utils.fromWei(res), "ether");
          setbusdlpt(noround(web3.utils.fromWei(res), 3), "ether");
        }
      });
  };

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return parseFloat(num).toFixed(2); // if value < 1000, nothing to do
    }
  }
  return (
    <div class="detailCard secpadding mb20">
      <div class="swapdelrow brtlr bbff">
        <p>1 LPT</p>
        <p>${marketData.pools
          ? noround(parseFloat(marketData.pools.busdlptusdPC.toString()), 4)
          : "0.00"}{" "}</p>
      </div>
      <div class="swapdelrow bbff">
        <p>Yield per block</p>
        <p>{noround(yieldBlock, 6)} EGOLD</p>
      </div>
      <div class="swapdelrow brblr mb20">
        <p>LP staked</p>
        <p>{lptStaked} LPT</p>
      </div>
      <div class="swapdelrow brtlr bbff">
        <p>LPT deposit value</p>
        <p>$  {marketData.pools
          ? numFormatter(marketData.pools.busdlptusdPC * busdlpt)
          : "0.00"}{" "}</p>
      </div>

      <div class="swapdelrow bbff">
        <p style={{ paddingRight: "10px" }}>Annual Egold Yield</p>
        <p style={{ textAlign: "right" }}>{noround(yieldBlock * 1200 * 24 * 365, 2)} EGOLD /{" "}
          {egtradeData.loading == false
            ? numFormatter(
              1200 * 24 * 365 * yieldBlock * egtradeData.data[0].TAG_USD
            )
            : "0.00"}{" "} USD</p>

      </div>
      <div class="swapdelrow brblr">
        <p>APY</p>
        <p> {marketData.pools && !egtradeData.loading
          ? (
            ((1200 * 24 * 365 * yieldBlock * egtradeData.data[0].TAG_USD +
              marketData.pools.busdlptusdPC * busdlpt -
              marketData.pools.busdlptusdPC * busdlpt) /
              (marketData.pools.busdlptusdPC * busdlpt)) *
            100
          ).toFixed(3)
          : "..."}{" "}%</p>
      </div>
    </div>
  );
}
