import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import ErrorCard from "../../LoaderCard/ErrorCard";
import dataVal from "../../../data/Abis.json";
import updown from "../../../images/updown.svg";
import PoolData from "../PoolData";
import TokenModal from "../TokenModal/TokenModal";
import { invokeservice } from "../../../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import TradeData from "./TradeData";
export default function TradeWidget(props) {
  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const datatok = [
    {
      id: 1,
      label: "BUSD",
      icon: "busdicon",
      tokencontract: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    {
      id: 2,
      label: "BNB",
      icon: "bnbicon",
      tokencontract: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    {
      id: 3,
      label: "EGOLD (V1)",
      icon: "egoldicon",
      tokencontract: "0x8005D97E993668a528008d16338B42f9e976ED0F",
    },
    {
      id: 4,
      label: "EGOLD (V2)",
      icon: "egoldicon",
      tokencontract: "0xBE76F927d274072266caDe09Daa54750cd4293a1",
    },
    {
      id: 5,
      label: "WBNB",
      icon: "bnbicon",
      tokencontract: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    {
      id: 6,
      label: "USDT",
      icon: "usdticon",
      tokencontract: "0x55d398326f99059fF775485246999027B3197955",
    },
    // {
    //   id: 5,
    //   label: "CBK",
    //   icon: "cbkicon",
    //   tokencontract: "0x14E3460453412Da9D4e635a4292eC95F360E5bF8",
    // },
  ];

  const [FromGlobal, setFromGlobal] = useState("USDT");
  const [ToGlobal, setToGlobal] = useState("EGOLD (V2)");
  const [Fswapamnt, setFswapamnt] = useState(null);
  const [Tswapamnt, setTswapamnt] = useState(null);
  const [fromTokenBalance, setfromTokenBalance] = useState(0.0);
  const [toTokenBalance, settoTokenBalance] = useState(0);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [loadapprove, setLoadapprove] = useState(false); //approve button loader

  const [rangevalue, setrangeValue] = useState(1);
  const [keyval, setkeyval] = useState(0);
  const [Tkeyval, setTkeyval] = useState(0);
  const [swapBtn, setswapBtn] = useState(true);
  const [OTP, setOTP] = useState("");

  //To update FromGlobal from modal
  const updateFromValue = (newValue, newValue2) => {
    setFromGlobal(newValue);
    setToGlobal(newValue2);
  };

  //To update ToGlobal from modal
  const updateToValue = (newValue) => {
    setToGlobal(newValue);
  };

  useEffect(() => {
    if (localStorage.getItem("acct")) {
      console.log("active");
      setFromGlobal("USDT");
      setToGlobal("EGOLD (V2)");
      getSummary(
        datatok.find((item) => item.label == "USDT").tokencontract,
        datatok.find((item) => item.label == "EGOLD (V2)").tokencontract
      );
    } else {
      setrangeValue(1);
      setFromGlobal("USDT");
      setToGlobal("EGOLD (V2)");
      setFswapamnt(null);
      setTswapamnt(null);
      setfromTokenBalance(0.0);
      settoTokenBalance(0);
      getSummary(
        datatok.find((item) => item.label == "USDT").tokencontract,
        datatok.find((item) => item.label == "EGOLD (V2)").tokencontract
      );
    }
  }, []);

  useEffect(() => {
    setFromGlobal("USDT");
    setToGlobal("EGOLD (V2)");
    getSummary(
      datatok.find((item) => item.label == "USDT").tokencontract,
      datatok.find((item) => item.label == "EGOLD (V2)").tokencontract
    );
  }, []);

  useEffect(() => {
    refreshState();
  }, [FromGlobal, ToGlobal]);

  useEffect(() => {
    getOnchangefrom(Fswapamnt);
  }, [FromGlobal]);

  useEffect(() => {
    getOnchangeto(Tswapamnt);
  }, [ToGlobal]);

  const getSummary = async (fromcontract, tocontract) => {
    if (FromGlobal !== "BNB") {
      var fromtokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        fromcontract
      );
    }
    if (ToGlobal !== "BNB") {
      var totokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        tocontract
      );
    }

    if (localStorage.getItem("acct")) {
      if (FromGlobal == "BNB") {
        var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
        setfromTokenBalance(noround(web3.utils.fromWei(xbal), 5));
      } else {
        await fromtokencontractInstance.methods
          .balanceOf(localStorage.getItem("acct"))
          .call()
          .then((res) => {
            setfromTokenBalance(noround(web3.utils.fromWei(res), 3));
          });
      }
      if (ToGlobal == "BNB") {
        var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
        settoTokenBalance(noround(web3.utils.fromWei(xbal), 5));
      } else {
        await totokencontractInstance.methods
          .balanceOf(localStorage.getItem("acct"))
          .call()
          .then((res) => {
            settoTokenBalance(noround(web3.utils.fromWei(res), 3));
          });
      }
    }
  };

  const refreshState = async () => {
    setprocessState({ state: "...", data: null });
    if (FromGlobal !== null)
      var fromcontract = datatok.find(
        (item) => item.label == FromGlobal
      ).tokencontract;
    if (ToGlobal !== null)
      var tocontract = datatok.find(
        (item) => item.label == ToGlobal
      ).tokencontract;

    if (FromGlobal !== "BNB" && FromGlobal !== null) {
      var fromtokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        fromcontract
      );
    }
    if (ToGlobal !== "BNB" && ToGlobal !== null) {
      var totokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        tocontract
      );
    }

    //these conditions are set to differentiate between bnb balance and other bep20 token balance
    if (localStorage.getItem("acct")) {
      if (FromGlobal !== null) {
        if (FromGlobal == "BNB") {
          var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
          setfromTokenBalance(noround(web3.utils.fromWei(xbal), 5));
        } else {
          await fromtokencontractInstance.methods
            .balanceOf(localStorage.getItem("acct"))
            .call()
            .then((res) => {
              setfromTokenBalance(noround(web3.utils.fromWei(res), 3));
            });
        }
      } else {
        setFswapamnt("");
        setfromTokenBalance(0.0);
      }
      if (ToGlobal !== null) {
        if (ToGlobal == "BNB") {
          var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
          settoTokenBalance(noround(web3.utils.fromWei(xbal), 5));
        } else {
          await totokencontractInstance.methods
            .balanceOf(localStorage.getItem("acct"))
            .call()
            .then((res) => {
              settoTokenBalance(noround(web3.utils.fromWei(res), 3));
            });
        }
      } else {
        setTswapamnt("");
        settoTokenBalance(0.0);
      }
    }
  };

  //to handle From input onchange
  const getOnchangefrom = async (value) => {
    setFswapamnt(value);
    if (value > 0) {
      setswapBtn(false);
      if (
        (FromGlobal == "BNB" && ToGlobal == "WBNB") ||
        (FromGlobal == "WBNB" && ToGlobal == "BNB") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V2)")
      ) {
        setTswapamnt(value);
      } else {
        const swapTokenval = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );
        var weiToken = web3.utils.toWei(value.toString(), "ether");
        await swapTokenval.methods
          .getAmountsOut(weiToken, [
            datatok.find((item) => item.label == FromGlobal).tokencontract,
            datatok.find((item) => item.label == ToGlobal).tokencontract,
          ])
          .call()
          .then((res) => {
            setTswapamnt(parseFloat(web3.utils.fromWei(res[1])).toFixed(4));
          });
      }
    } else {
      setTswapamnt(0.0);
    }
  };

  //to handle To input onchange
  const getOnchangeto = async (value) => {
    setTswapamnt(value);
    if (value > 0) {
      setswapBtn(false);
      if (
        (FromGlobal == "BNB" && ToGlobal == "WBNB") ||
        (FromGlobal == "WBNB" && ToGlobal == "BNB") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V2)")
      ) {
        setFswapamnt(value);
      } else {
        const swapTokenval = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );
        var weiToken = web3.utils.toWei(value.toString(), "ether");
        await swapTokenval.methods
          .getAmountsIn(weiToken, [
            datatok.find((item) => item.label == FromGlobal).tokencontract,
            datatok.find((item) => item.label == ToGlobal).tokencontract,
          ])
          .call(async function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("xixi-", res);
              setFswapamnt(parseFloat(web3.utils.fromWei(res[0])).toFixed(4));
            }
          });
      }
    } else {
      setFswapamnt(0.0);
    }
  };

  //To interchage From and To tokens
  function interChangeToken(from, to) {
    console.log("interchaange--", from, to);
    setTswapamnt(0.0);
    setFswapamnt(0.0);
    setkeyval(keyval + 1);
    setTkeyval(Tkeyval + 1);
    setFromGlobal(to);
    setToGlobal(from);
  }

  //to Handle From MAX
  function getMaxF() {
    setFswapamnt(fromTokenBalance);
    getOnchangefrom(fromTokenBalance);
  }

  //to handle To MAX
  function getMaxT() {
    setTswapamnt(toTokenBalance);
    getOnchangeto(toTokenBalance);
  }

  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." ||
      err.message.includes(
        "while converting number to string, invalid number value "
      )
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }
  const handleEGConvert = async (selectedItem) => {
    try {
      var Famount = String(Fswapamnt);
      const token = localStorage.getItem("accessToken");
      setprocessState({ state: "processing", data: null });
      var headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(token),
        },
      };
      let transferData = {}
      if (selectedItem == "2") {
        transferData = {
          job: {
            type: "ConvertV2toV1",
            params: {
              useragent: "mobile",
              user: localStorage.getItem("email"),
              amount: Famount,
              totp:
                localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",

            }
          }
        }
      } else if (selectedItem == "1") {
        transferData = {
          job: {
            type: "ConvertV1toV2",
            params: {
              useragent: "mobile",
              user: localStorage.getItem("email"),
              amount: Famount,
              totp:
                localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
            }
          }
        }
      }
      invokeservice(transferData, headers).then((json) => {
        if (json.data.status != "failed") {
          setprocessState({ state: "done", data: "Conversion done successfully" });
        } else {
          setprocessState({ state: "error", data: json.data.message });
        }
      });

    } catch (e) {
      setprocessState({ state: "error", data: JSON.stringify(e.message) });
      console.log(e);
    }

  };

  //To swap one token to another
  const swapToken = async (e) => {
    console.log("tokenssselected", FromGlobal, ToGlobal)
    if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
      setprocessState({ state: "...", data: null });
      var today = new Date();
      var futureDate = new Date(today.getTime() + 60000);
      var Famount = String(Fswapamnt);
      // var Tamount = String(rangevalue);
      var Tamount = parseFloat(
        ((100 - rangevalue) / 100) * parseFloat(Tswapamnt)
      )
        .toFixed(16)
        .toString();
      if (Fswapamnt != null) {
        var fromADDR = datatok.find(
          (item) => item.label == FromGlobal
        ).tokencontract;
        var toADDR = datatok.find(
          (item) => item.label == ToGlobal
        ).tokencontract;

        if (parseFloat(fromTokenBalance) >= parseFloat(Famount)) {
          if (FromGlobal == "BNB" && ToGlobal == "WBNB") {
            convertbnbtowbnb();
          } else if (FromGlobal == "WBNB" && ToGlobal == "BNB") {
            convertwbnbtobnb();
          } else if (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") {
            handleEGConvert(1);
          } else if (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") {
            handleEGConvert(2);
          } else {
            let tokennow = ""
            let tokensnow2 = ""
            if (FromGlobal == "EGOLD (V1)") {
              tokennow = "egold"
            } else if (FromGlobal == "EGOLD (V2)") {
              tokennow = "EGOLDV2"
            } else {
              tokennow = FromGlobal
            }
            if (ToGlobal == "EGOLD (V1)") {
              tokensnow2 = "EGOLD"
            } else if (ToGlobal == "EGOLD (V2)") {
              tokensnow2 = "EGOLDV2"
            } else {
              tokensnow2 = ToGlobal
            }
            try {
              setprocessState({ state: "processing", data: null });
              const token = localStorage.getItem("accessToken");
              const swapData = {
                job: {
                  type:
                    FromGlobal == "BNB"
                      ? "swapBnbforToken"
                      : ToGlobal == "BNB"
                        ? "swapTokenforBnb"
                        : "swapTokenforToken",
                  params: {
                    useragent: navigator.userAgent,
                    fromToken: fromADDR,
                    toToken: toADDR,
                    from: tokennow,
                    to: tokensnow2,
                    token:
                      FromGlobal == "BNB"
                        ? toADDR
                        : ToGlobal == "BNB"
                          ? fromADDR
                          : null,
                    fromAmount: Famount,
                    toAmount: Tamount,
                    totp:
                      localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
                  },
                },
              };
              var headers = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + String(token),
                },
              };
              invokeservice(swapData, headers).then((json) => {
                setOTP("");
                if (json.data.status != "failed") {
                  setswapBtn(true);
                  setprocessState({ state: "done", data: json.data.task_id });
                  getSummary(
                    datatok.find((item) => item.label == FromGlobal)
                      .tokencontract,
                    datatok.find((item) => item.label == ToGlobal).tokencontract
                  );
                } else {
                  setswapBtn(true);
                  setprocessState({ state: "error", data: json.data.message });
                }
              });
            } catch (err) {
              console.log(err);
            }
          }
        } else {
          setOTP("");
          setswapBtn(true);
          setprocessState({ state: "error", data: "Insufficient Balance" });
        }

      } else {
        setOTP("");
        setprocessState({ state: "error", data: "Invalid Amount" });
      }
    }
  };

  function convertbnbtowbnb() {
    setprocessState({ state: "processing", data: null });
    var Famount = String(Fswapamnt);
    const token = localStorage.getItem("accessToken");
    var headers = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(token),
      },
    };
    var convertdata = {
      job: {
        type: "ConvertBnbWbnb",
        params: {
          useragent: navigator.userAgent,
          amount: Famount,
          totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
        },
      },
    };

    invokeservice(convertdata, headers).then((json) => {
      setOTP("");
      if (json.data.status != "failed") {
        setswapBtn(true);
        setprocessState({ state: "done", data: json.data.task_id });
        getSummary(
          datatok.find((item) => item.label == FromGlobal)
            .tokencontract,
          datatok.find((item) => item.label == ToGlobal).tokencontract
        );
      } else {
        setOTP("");
        setswapBtn(true);
        setprocessState({ state: "error", data: json.data.message });
      }
    });
  }
  function convertwbnbtobnb() {
    setprocessState({ state: "processing", data: null });
    var Famount = String(Fswapamnt);
    const token = localStorage.getItem("accessToken");
    var headers = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(token),
      },
    };
    var convertdata = {
      job: {
        type: "ConvertWbnbtoBnb",
        params: {
          useragent: navigator.userAgent,
          amount: Famount,
          totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
        },
      },
    };

    invokeservice(convertdata, headers).then((json) => {
      setOTP("");
      if (json.data.status != "failed") {
        setswapBtn(true);
        setprocessState({ state: "done", data: json.data.task_id });
        getSummary(
          datatok.find((item) => item.label == FromGlobal)
            .tokencontract,
          datatok.find((item) => item.label == ToGlobal).tokencontract
        );
      } else {
        setOTP("");
        setswapBtn(true);
        setprocessState({ state: "error", data: json.data.message });
      }
    });
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {datatok.find((item) => item.label == FromGlobal).tokencontract ==
        datatok.find((item) => item.label == ToGlobal).tokencontract ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") ? (
        ""
      ) : (
        <PoolData
          from={datatok.find((item) => item.label == FromGlobal)}
          to={datatok.find((item) => item.label == ToGlobal)}
        />
      )}
      <div class="detailCard secpadding mb20">
        <div class="fromreactangle pr mb20">
          <div class="p15">
            <p class="fs12 mb10">From</p>
            <p class="fromreactp">
              {" "}
              <input
                className="swapinput"
                placeholder="0.00"
                key={keyval}
                value={Fswapamnt}
                onChange={(e) => getOnchangefrom(e.target.value)}
              />
              <TokenModal
                currenttoken={FromGlobal}
                datatok={datatok}
                updateParentValue={updateFromValue}
              />
            </p>
            <p class="fs12">
              Balance: {FromGlobal == null ? "0.0" : fromTokenBalance}{" "}
              <span
                class="float-right yellowtext fs14"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => getMaxF()}
              >
                Use Max
              </span>
            </p>
          </div>
          <div
            class="updownimgb"
            style={{ cursor: "pointer" }}
            onClick={(e) => interChangeToken(FromGlobal, ToGlobal)}
          >
            <img src={updown} alt="" />
          </div>
        </div>
        <div class="fromreactangle mb20">
          <div class="p15">
            <p class="fs12 mb10">To</p>
            <p class="fromreactp">
              <input
                className="swapinput"
                placeholder="0.00"
                key={Tkeyval}
                value={Tswapamnt}
                onChange={(e) => getOnchangeto(e.target.value)}
              />
              <TokenModal
                currenttoken={ToGlobal}
                datatok={datatok}
                updateParentValue={updateFromValue}
              />
            </p>
            <p class="fs12">
              Balance: {ToGlobal == null ? "0.0" : toTokenBalance}{" "}
              <span
                class="float-right yellowtext fs14"
                style={{
                  cursor: "pointer",
                }}
                onClick={(e) => getMaxT()}
              >
                Use Max
              </span>
            </p>
          </div>
        </div>
        <div class="fromreactangle p15 mb15">
          <div class="rangevalue mb15">
            <p>
              Set Slippage Tolerance: <span id="rangeValue">{rangevalue}</span>%{" "}
            </p>
          </div>
          <input
            class="customrange mb15"
            type="range"
            name=""
            min="1"
            max="100"
            value={rangevalue}
            onChange={(e) => {
              setrangeValue(e.target.value);
            }}
          />
          <div class="rangevalue">
            <p>
              Minimum Received:{" "}
              {isNaN(
                Number(Tswapamnt - (rangevalue / 100) * Tswapamnt).toFixed(4)
              )
                ? "0.0000"
                : Number(Tswapamnt - (rangevalue / 100) * Tswapamnt).toFixed(4)}
            </p>
          </div>
        </div>
        {localStorage.getItem("isoptionaltfa") == "true" ? (
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <div style={{ marginTop: 10 }}>
              <label for="receiver_address" class="form-label">
                Please enter 2FA Code :
              </label>
              <InputGroup>
                <Input
                  name="otp"
                  type={"text"}
                  id="otp"
                  value={OTP}
                  onChange={(otp) => {
                    setOTP(otp.target.value);
                  }}
                  style={{
                    borderTop: "0.489247px solid rgb(222, 226, 230)",
                  }}
                  className="passwordbox"
                />

                <InputGroupText
                  className={"inputicon"}
                  onClick={async () => {
                    setOTP(await navigator.clipboard.readText());
                  }}
                >
                  <FaRegPaste color="black" size={22} />
                </InputGroupText>
              </InputGroup>
            </div>
          </div>
        ) : null}
        <button
          class="btn-color-primary"
          style={{ cursor: "pointer", width: "100%", border: "0px" }}
          onClick={() => {
            swapToken();
          }}
          disabled={
            (FromGlobal == "BNB" && ToGlobal !== null) ||
              (FromGlobal == "WBNB" && ToGlobal == "BNB") || FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)"
              ? false
              : swapBtn
          }
        >
          Swap token
        </button>
        {processState.state == "..." ? (
          ""
        ) : processState.state == "processing" ? (
          <LoaderCard />
        ) : processState.state == "done" ? (
          <ConfirmCard tx={processState.data} />
        ) : (
          <ErrorCard err={processState.data} />
        )}

      </div>
      <TradeData from={FromGlobal} to={ToGlobal} />
    </>
  );
}
