import React, { useEffect, useState } from "react";
import PillsMenu from "./Menu/PillsMenu";
import TradePillsMenu from "./Trade/TradePillsMenu";
import PoolData from "./Trade/PoolData";
import LiquidityAddWidget from "./Trade/Liquidity/v2/LiquidityAddWidget";
import LiquidityRemoveWidget from "./Trade/Liquidity/v2/LiquidityRemoveWidget";
import LiquidityData from "./Trade/Liquidity/v2/LiquidityData";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

export default function Liquidity() {
  return (
    <>
      <div
        class="mainsection"
        style={{
          position: "relative",
        }}
      >
        <div class="minertabsec secpadding lightgrey-bg brrr mb20">
          <PillsMenu activeval="trade" />
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-trade" role="tabpanel" aria-labelledby="pills-trade-tab" tabindex="0">
              <div class="wallettabsec egoldsubtabsec lightgrey-bg brtlr">
                <TradePillsMenu activeval="liquidity" />
                <div class="tab-content" id="pills-tabContent">

                  <div class="tab-pane fade show active" id="pills-liquidity" role="tabpanel" aria-labelledby="pills-liquidity-tab" tabindex="0">

                    <LiquidityAddWidget />
                    <LiquidityRemoveWidget />
                    <LiquidityData />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
