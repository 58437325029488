import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";

const TotaleSupply = () => {
  const Web3 = require("web3");

  const [data, setData] = useState([]);

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <>
      <text
        x={centerX}
        y={centerY - 32}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "18px",
        }}
      >
       EGOLD
      </text>
      <text
        x={centerX}
        y={centerY - 8}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "18px",
        }}
      >
      Distribution
      </text>
      <text
        x={centerX}
        y={centerY + 28}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
         21 M
      </text>
      </>
    );
  };

  useEffect(() => {
    setData([
      {
        id: "Mining",
        label: "Mining",
        value: noround(20000000, 2),
        color: "#FFC727",
      },
      {
        id: "Liquidity Farming",
        label: "Liquidity Farming",
        value: noround(180000, 2),
        color: "#FFE18C",
      },
      {
        id: "Other",
        label: "Other Allocations",
        value: noround(820000, 2),
        color: "#4F6B75",
      },
    ]);
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      <div className="chartbx">
        <div className="chbxhdiv">
          <ResponsivePie
            data={data}
            margin={
              window.innerWidth < 500
                ? { top: 20, right: 20, bottom: 0, left: 20 }
                : { top: 40, right: 80, bottom: 40, left: 80 }
            }
            valueFormat=" >-,"
            startAngle={10}
            endAngle={-350}
            sortByValue={false}
            activeOuterRadiusOffset={8}
            enableArcLinkLabels={window.innerWidth < 500 ? false : true}
            enableArcLabels={false}
            arcLinkLabel={(e) => `${e.id}\n(${e.value})`}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="#ffffff"
            innerRadius={0.45}
            layers={[
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              "legends",
              CenteredMetric,
            ]}
            colors={(slice) => slice.data.color}
          />
        </div>
       
          <div class="miningcapacity" id="miningCapacitySection">
            <div class="detailCard secpadding mb20">
              <div class="bardelshowp mb10">
                <div class="primarybardot"></div>Mining :{" "}
                {parseFloat(noround(20000000, 2)).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="secondarybardot"></div>
                Other Allocations :{" "}
                {parseFloat(noround(820000, 2)).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="otherbardot"></div>
                Liquidity Farming :{" "}
                {parseFloat(noround(180000, 2)).toLocaleString()}
              </div>
            </div>
          </div>
      </div>
    </>
  );
};
export default TotaleSupply;
