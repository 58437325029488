import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import dataVal from "../data/Abis.json";
import { invokeservice } from "../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import {
  Input,
  InputGroup,
  InputGroupText
} from "reactstrap"

import LoaderCard from "./LoaderCard/LoaderCard";
import ConfirmCard from "./LoaderCard/ConfirmCard";
import ErrorCard from "./LoaderCard/ErrorCard";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
const NFT_ABI = dataVal.nftAbi;
const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
const nftContractInstance = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);

export default function Transfer() {
  let navigate = useNavigate();
  let params = useParams();
  const [minerName, setminerName] = useState("...");
  const [recieveAddr, setrecieveAddr] = useState("");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");


  let miner_id = params.id;

  useEffect(() => {
    getMinerDetails();
  }, []);

  async function getMinerDetails() {
    await nftContractInstance.methods
      .fetchMinerInfo(params.id)
      .call()
      .then((res) => {
        console.log("response", res.minerName);
        setminerName(res.minerName);
      });
  }

  async function safeTransfer() {

    try {

      const token = localStorage.getItem("accessToken");
      console.log("isaddress", web3.utils.isAddress(recieveAddr), recieveAddr)
      if (!web3.utils.isAddress(recieveAddr)) {
        setprocessState({
          state: "error",
          data: "Invalid receiver Address",
        });
      } else {
        setprocessState({ state: "processing", data: null });
        var headers = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };

        const claimdata = {
          job: {
            type: "TransferMiner",
            params: {
              useragent: navigator.userAgent,
              user: localStorage.getItem("email"),
              miner_id: miner_id,
              to_address: recieveAddr,
              totp: OTP,
            },
          },
        };
        console.log("claimdata-", claimdata);
        invokeservice(claimdata, headers).then((json) => {

          if (json.data.status != "failed") {
            setprocessState({ state: "done", data: "Miner sent successfully" });
          } else {
            setprocessState({ state: "error", data: json.data.message });
          }
        });
      }
    } catch (e) {
      setprocessState({ state: "error", data: JSON.stringify(e.message) });
      console.log(e);
    }
  }

  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="Transfer Miner" ReRoute="-1" />
          <div class="detailCard secpadding mb20">
            <div class="minerAvailableCard mb10">
              <div class="minerAvailableTitle mb20">
                EGOLD Miner <span class="">{minerName}</span>
              </div>

              <div class="minerAvailableImage">
                {minerName !== "..." ? (
                  <img
                    src={require("../images/miners/" + minerName + ".png")}
                    class="img-fluid"
                  />
                ) : null}
              </div>

              <a class="minerAvailablebtn">Miner ID: {miner_id}</a>
            </div>

            <label for="receiver_address" class="form-label">
              Receiver Address
            </label>
            <input
              type="email"
              class="form-control mb20"
              id="receiver_address"
              value={recieveAddr}
              onChange={(e) => {
                setrecieveAddr(e.target.value);
              }}
            />

            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <div style={{ marginTop: 10 }}>
                <label for="receiver_address" class="form-label">
                  Please enter 2FA Code
                </label>
                <InputGroup>
                  <Input
                    name="otp"
                    type={"text"}
                    id="otp"
                    value={OTP}
                    onChange={(otp) => {
                      setOTP(otp.target.value);
                    }}
                    style={{
                      borderTop: "0.489247px solid rgb(222, 226, 230)",
                    }}
                    className='passwordbox'

                  />

                  <InputGroupText
                    className={"inputicon"}
                    onClick={async () => {
                      setOTP(await navigator.clipboard.readText());
                    }}
                  >
                    <FaRegPaste color='black' size={22} />

                  </InputGroupText>
                </InputGroup>
              </div>

            </div>
            <a
              class="btn-color-primary mb20"
              style={{ cursor: "pointer" }}
              onClick={() => {
                safeTransfer();
              }}
            >
              Transfer Miner
            </a>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard bg="dark" />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} bg="dark" />
          ) : (
            <ErrorCard err={processState.data} bg="dark" />
          )}
        </div>

        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            You can tranfer your Miner ANFT to another wallet. Please provide
            external wallet address that you would like to send your miner to.
          </p>
        </div>
      </div>
    </>
  );
}
