import React, { useEffect, useState } from "react";
import dataVal from "../../data/Abis.json";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import downarrow from "../../images/downarrow.svg";
import egold from "../../images/currency/egold.svg";
import link from "../../images/link.svg";
import crossicon from "../../images/cross.svg";
import { FaRegPaste } from "react-icons/fa6";
import { invokeservice } from "../../api/apiService";
import {
  Input,
  InputGroup,
  InputGroupText,
  Spinner
} from "reactstrap";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function StakeCard(props) {
  const [amount, setAmount] = useState("");
  const [egoldBalance, setEgoldBalance] = useState("...");
  const [activeElementId, setActiveElementId] = useState(null);
  const [egoldstakedAmnt, setegoldstaked] = useState("...");
  const [pending, setpending] = useState("...");
  const [endBlk, setendBlk] = useState("...");
  const [startBlk, setstartBlk] = useState("...");
  const [totalEgoldStaked, settotalEgoldStaked] = useState("...");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [currentblockNumber, setcurrentblockNumber] = useState("...");
  const [approvedEgold, setapproveEGOLD] = useState(false);
  const [stakeApproveLoader, setStakeApproveLoader] = useState(false);
  const [btnLoader, setbtnLoader] = useState(false);
  const [unbtnLoader, setunbtnLoader] = useState(false);
  const [CLOTP, setCLOTP] = useState("");
  const [OTP, setOTP] = useState("");


  const showData = (elementId) => {
    setprocessState({ state: "...", data: null });
    const isActive = elementId === activeElementId;
    setActiveElementId(isActive ? null : elementId);
  };

  useEffect(() => {
    fetchData();
  }, [props.itemdata]);

  async function fetchData() {
    console.log("selected props=", props)
    const cbn = await web3.eth.getBlockNumber();
    setcurrentblockNumber(cbn);
    const farmContractInstance = await new web3.eth.Contract(
      dataVal.stakefarmabi,
      props.itemdata.id
    );
    const egoldcontractInstance = await new web3.eth.Contract(
      dataVal.tokenabi,
      process.env.REACT_APP_EGOLD_ADDR
    );
    try {
      await farmContractInstance.methods
        .pending(0, localStorage.getItem("acct"))
        .call(async function (err, res) {
          if (err) {
            console.log("error-", err);
          }
          console.log("PENDING RES", res, res == undefined);
          if (res == undefined) {
            await farmContractInstance.methods
              .userInfo(0, localStorage.getItem("acct"))
              .call(async function (err, ress) {
                if (err) {
                  console.log("error-", err);
                }
                console.log("the rest-",props.itemdata.id,"-",ress.rewardDebt);
                setpending(noround(web3.utils.fromWei(ress.rewardDebt), 5))
              });
          } else setpending(noround(web3.utils.fromWei(res), 5));
          // if (res == undefined) setpending(0);
          // else setpending(noround(web3.utils.fromWei(res), 5));
        });
    } catch (e) {
      console.log("error", e);
      setpending(0);
    }

    await farmContractInstance.methods
      .startBlock()
      .call(async function (err, res) {
        console.log("strtblk", res);
        setstartBlk(res);
      });

    await farmContractInstance.methods
      .endBlock()
      .call(async function (err, res) {
        console.log("endblk", res);
        setendBlk(res);
      });

    try {
      await farmContractInstance.methods
        .deposited(0, localStorage.getItem("acct"))
        .call(async function (err, res) {
          if (err) {
            console.log("error-", err);
          }
          console.log("STAKED RES", res, res == undefined);
          if (res == undefined) setegoldstaked(0);
          else setegoldstaked(noround(web3.utils.fromWei(res), 5));
        });
    } catch (e) {
      console.log("error", e);
      setpending(0);
    }

    await egoldcontractInstance.methods
      .balanceOf(localStorage.getItem("acct"))
      .call({}, function (e, res) {
        console.log("BALANCE", noround(web3.utils.fromWei(res), 5));
        setEgoldBalance(noround(web3.utils.fromWei(res), 5));
      });

    await egoldcontractInstance.methods
      .balanceOf(props.itemdata.id)
      .call({}, function (e, res) {
        if (props.cmsdata.attributes.projectName == "EGOLD") {
          settotalEgoldStaked(
            noround(
              web3.utils.fromWei(res) - props.cmsdata.attributes.TokensOffered,
              5
            )
          );
        } else {
          settotalEgoldStaked(noround(web3.utils.fromWei(res), 5));
        }
      });
  }

  function checkerror(err) {
    if (
      err.message ==
      "Please pass numbers as strings or BN objects to avoid precision errors." ||
      err.message.includes(
        "while converting number to string, invalid number value "
      )
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }


  const confirmStake = async () => {
    setbtnLoader(true);
    setprocessState({ state: "...", data: null });
    if (localStorage.getItem("acct")) {
      try {
        setprocessState({ state: "processing", data: null });
        const token = localStorage.getItem("accessToken");
        const stakeData = {
          job: {
            type: "depositOnfarms",
            params: {
              "useragent": "mobile",
              "farmaddress": props.itemdata.id,
              "amount": amount,
              totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
            }
          },
        };
        var headers = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };
        invokeservice(stakeData, headers).then((json) => {
          if (json.data.status != "failed") {
            fetchData();
            setbtnLoader(false);
            setprocessState({
              state: "done",
              data: json.data.task_id
            });
          } else {
            setbtnLoader(false);
            setprocessState({
              state: "error",
              data: json.data.message
            });
          }
        });
      }
      catch (error) {
        setbtnLoader(false);
        checkerror(error);
      }
    }
  };

  const confirmUnStake = async () => {
    setunbtnLoader(true);
    setprocessState({ state: "...", data: null });
    if (localStorage.getItem("acct")) {
      const token = localStorage.getItem("accessToken");
      setprocessState({ state: "processing", data: null });
      const stakeData = {
        job: {
          type: "withdrawFromfarms",
          params: {
            "useragent": "mobile",
            "farmaddress": props.itemdata.id,
            "amount": egoldstakedAmnt,
            totp: localStorage.getItem("isoptionaltfa") == "true" ? CLOTP : "",
          }
        },
      };
      var headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(token),
        },
      };
      invokeservice(stakeData, headers).then((json) => {
        console.log("json ", json)
        if (json.data.status != "failed") {
          setunbtnLoader(false);
          setprocessState({ state: "done", data: json.data.task_id });
          fetchData();
        } else {
          setunbtnLoader(false);
          setprocessState({
            state: "error",
            data: json.data.message
          });

        }
      });
    }
  };

  function calculateFMperiod(start, end) {
    var difference = end - start;
    difference = difference * 3;
    let days = difference / 86400;
    console.log("DAYS", days);
    return parseInt(days);
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  async function getTimeUntilBlock(blockNumber) {
    try {
      const latestBlock = await web3.eth.getBlockNumber();

      if (blockNumber <= latestBlock) {
        return "0d:0h:0m";
      }

      const blockTime = 3;

      const remainingBlocks = blockNumber - latestBlock;
      const remainingTimeInSeconds = remainingBlocks * blockTime;

      const days = Math.floor(remainingTimeInSeconds / (3600 * 24));
      const hours = Math.floor((remainingTimeInSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((remainingTimeInSeconds % 3600) / 60);

      const formattedTime = `${days}d:${hours}h:${minutes}m`;

      return `${formattedTime}`;
    } catch (error) {
      return `Error: ${error.message}`;
    }
  }

  function BlockTimer({ targetBlockNumber }) {
    const [remainingTime, setRemainingTime] = useState("...");

    useEffect(() => {
      const init = async () => {
        const timeRemaining = await getTimeUntilBlock(targetBlockNumber);
        setRemainingTime(timeRemaining);
      };
      init();
      const interval = setInterval(async () => {
        const timeRemaining = await getTimeUntilBlock(targetBlockNumber);
        setRemainingTime(timeRemaining);

        if (timeRemaining === "0h:0m:0s") {
          clearInterval(interval);
        }
      }, 30000);

      return () => clearInterval(interval);
    }, [targetBlockNumber]);

    return <p className="tilespdel">{remainingTime}</p>;
  }
  function checkAPR(rws, price, egstkd, tokoff, egprice) {
    var x = noround(
      ((rws * 20 * 60 * 24 * 365 * price) /
        (noround(egstkd - tokoff, 5) * egprice)) *
      100,
      2
    );
    return isNaN(x);
  }

  function findAPR(rws, price, egstkd, tokoff, egprice) {
    var x = noround(
      ((rws * 20 * 60 * 24 * 365 * price) /
        (noround(egstkd - tokoff, 5) * egprice)) *
      100,
      2
    );
    return x + "%";
  }

  function getAPR() {
    return props.cmsdata.attributes.projectName == "EGOLD"
      ? props.dailystate.status == false ||
        props.priceinfo.status == false ||
        props.priceinfo.data[props.cmsdata.attributes.farmAddress] == undefined
        ? "..."
        : checkAPR(
          props.itemdata.rwsblk,
          props.priceinfo.data[props.cmsdata.attributes.farmAddress],
          parseFloat(props.itemdata.egoldstkd),
          parseFloat(props.cmsdata.attributes.TokensOffered),
          parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
        )
          ? "0%"
          : findAPR(
            props.itemdata.rwsblk,
            props.priceinfo.data[props.cmsdata.attributes.farmAddress],
            parseFloat(props.itemdata.egoldstkd),
            parseFloat(props.cmsdata.attributes.TokensOffered),
            parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
          )
      : props.dailystate.status == false ||
        props.priceinfo.status == false ||
        props.priceinfo.data[props.cmsdata.attributes.farmAddress] == undefined
        ? "..."
        : checkAPR(
          props.itemdata.rwsblk,
          props.priceinfo.data[props.cmsdata.attributes.farmAddress],
          parseFloat(props.itemdata.egoldstkd),
          0,
          parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
        )
          ? "0%"
          : findAPR(
            props.itemdata.rwsblk,
            props.priceinfo.data[props.cmsdata.attributes.farmAddress],
            parseFloat(props.itemdata.egoldstkd),
            0,
            parseFloat(props.dailystate.data[0].TAG_USD).toFixed(4)
          );
  }
  return (
    <div
      class="stakeliverow"
      style={
        props.selected != "all" && (egoldstakedAmnt == 0 || pending == 0)
          ? { display: "none" }
          : {}
      }
    >
      <div class="slfsgroup">
        <div class="slfirst">
          {props.type == "live" ? (
            <span class="stakelivebtn">APR : {getAPR()}</span>
          ) : (
            <span class="stakelivebtn"> APR: ...% </span>
          )}
          {props.type == "live" && <span class="livebadge"> Live </span>}
          {props.type == "completed" && (
            <span class="completedbadge"> Completed </span>
          )}
          {props.type == "upcoming" && (
            <span class="upcomingbadge"> Upcoming </span>
          )}
        </div>
        <div class="slsecond">
          <span>
            {" "}
            STAKE: EGOLD <img src={egold} alt="" style={{ height: "20px" }} />
          </span>
          <span class="float-right">
            {" "}
            Earn:{" "}
            {props.cmsdata.attributes.projectName == "EGOLD"
              ? props.cmsdata.attributes.projectName
              : props.itemdata.tokname}{" "}
            <img
              src={props.cmsdata.attributes.icon.data.attributes.url}
              style={{ height: "20px" }}
              alt=""
            />
          </span>
        </div>
      </div>
      {egoldstakedAmnt > 0 || pending > 0 ? (
        <div class="slthird">
          <span> You staked: {egoldstakedAmnt} EGOLD </span>
          <span class="float-right"> You earned: {pending} BNB </span>
        </div>
      ) : (
        ""
      )}
      <div
        className={`slfourth ${activeElementId ? "active" : ""}`}
        id="fourslidebtn"
      >
        <div class="slfourthbtns">
          <button
            class="slfourthbtn"
            id="detbtn"
            onClick={() => showData("fourthslidecontent", "detbtn")}
          >
            {" "}
            Details <img src={downarrow} alt="" />
          </button>
          <button
            class="slfourthbtn"
            id="infobtn"
            onClick={() => showData("fourthslidecontent2", "infobtn")}
          >
            {" "}
            {props.cmsdata.attributes.projectName == "EGOLD"
              ? props.cmsdata.attributes.projectName
              : props.itemdata.tokname}{" "}
            Info <img src={downarrow} alt="" />
          </button>
        </div>
        <div class="slfourthbtns">
          {props.type == "completed" && pending > 0 && (
            <div
              className={`slcollectbtn ${activeElementId === "fourthslidecontent3" ? "active" : ""
                }`}
              id="cltbtn"
              onClick={() => showData("fourthslidecontent3", "cltbtn")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Collect{" "}
            </div>
          )}
          {props.type == "live" && (
            <div
              className={`slstakebtn ${activeElementId === "fourthslidecontent4" ? "active" : ""
                }`}
              id="stkbtn"
              onClick={() => showData("fourthslidecontent4", "stkbtn")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Stake{" "}
            </div>
          )}
        </div>
      </div>
      <div
        id="fourthslidecontent"
        className={`slide-down-content ${activeElementId === "fourthslidecontent" ? "active" : ""
          }`}
      >
        <div class="performancehistroy pb-0 slfourthdel">
          <div>
            <p class="minerownep">
              {props.cmsdata.attributes.projectName == "EGOLD"
                ? props.cmsdata.attributes.projectName
                : props.itemdata.tokname}{" "}
              available
            </p>
            <p class="minerownep2">{props.cmsdata.attributes.TokensOffered}</p>
          </div>
          <div>
            <p class="minerownep">EGOLD staked </p>
            <p class="minerownep2">{totalEgoldStaked}</p>
          </div>
          <div>
            {props.type == "live" ? (
              <>
                <p class="minerownep">APR </p>
                <p class="minerownep2">{getAPR()}</p>
              </>
            ) : (
              <>
                <p class="minerownep">APR </p>
                <p class="minerownep2">...%</p>
              </>
            )}
          </div>
        </div>
        <div class="performancehistroy slfourthdel">
          <div>
            <p class="minerownep">Farming period</p>
            <p class="minerownep2">
              ~
              {startBlk == "..." || endBlk == "..."
                ? "..."
                : calculateFMperiod(startBlk, endBlk)}{" "}
              day/s
            </p>
          </div>
          <div>
            <p class="minerownep">Start block</p>
            <a href={"https://bscscan.com/block/" + startBlk} target="_blank">
              <p class="minerownep2">#{startBlk}</p>
            </a>
          </div>
          <div>
            <p class="minerownep">End block </p>
            <a href={"https://bscscan.com/block/" + endBlk} target="_blank">
              <p class="minerownep2 ">#{endBlk}</p>
            </a>
          </div>
        </div>
      </div>
      <div
        class="slide-down-content"
        id="fourthslidecontent2"
        className={`slide-down-content ${activeElementId === "fourthslidecontent2" ? "active" : ""
          }`}
      >
        <div class="slidecontent">
          <p class="slidecontentp">{props.cmsdata.attributes.description}</p>
          <div class="tokenmorebtsn">
            <a
              href={
                "https://bscscan.com/address/" +
                props.cmsdata.attributes.tokensAddress
              }
              target="_blank"
              style={{ color: "unset" }}
            >
              <span>
                <img src={link} alt="" />
                Token contract{" "}
              </span>
            </a>
            <a
              style={{ color: "unset" }}
              href={
                props.cmsdata.attributes.website.includes("http")
                  ? props.cmsdata.attributes.website
                  : "//" + props.cmsdata.attributes.website
              }
              target="_blank"
            >
              <span>
                <img src={link} alt="" />
                More info{" "}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div
        class="slide-down-content"
        id="fourthslidecontent4"
        className={`slide-down-content ${activeElementId === "fourthslidecontent4" ? "active" : ""
          }`}
      >
        <div class="slidecontent">
          <p class="stakegoldp">
            Stake EGOLD{" "}
            <img
              src={crossicon}
              alt=""
              class="float-right"
              onClick={() => {
                setprocessState({ state: "...", data: null });
                setActiveElementId(null);
              }}
              style={{ cursor: "pointer" }}
            />
          </p>
          <div class="sliderinputsec">
            <div class="sliderinput">
              <input
                type="text"
                class="form-control "
                value={amount}
                placeholder="Enter Amount"
                onChange={(amount) => {
                  setAmount(amount.target.value);
                }}
              />
              <p class="labelegold">Egold</p>
              <p
                class="labelmax"
                style={{ cursor: "pointer" }}
                onClick={(e) => setAmount(egoldBalance)}
              >
                Max
              </p>
            </div>
            <p class="labelavailbalp">
              Balance: {egoldBalance} EGOLD
            </p>
          </div>
          {
            localStorage.getItem("isoptionaltfa") == "true" ?
              <div style={{ marginTop: 20, marginBottom: 30 }}>
                <div style={{ marginTop: 10 }}>
                  <label for="receiver_address" class="form-label">
                    Please enter 2FA Code :
                  </label>
                  <InputGroup>
                    <Input
                      name="otp"
                      type={"text"}
                      id="otp"
                      value={OTP}
                      onChange={(otp) => {
                        setOTP(otp.target.value);
                      }}
                      style={{
                        borderTop: "0.489247px solid rgb(222, 226, 230)",
                      }}
                      className='passwordbox'
                    />
                    <InputGroupText
                      className={"inputicon"}
                      onClick={async () => {
                        setOTP(await navigator.clipboard.readText());
                      }}
                    >
                      <FaRegPaste color='black' size={22} />
                    </InputGroupText>
                  </InputGroup>
                </div>

              </div> : null
          }
          <div class="selectcurbtnsec">
            <button
              class="btn-color-primary"
              style={{ border: "0px", width: "100%" }}
              onClick={() => {
                if (parseFloat(amount) > 0) {
                  if (parseFloat(amount) > parseFloat(egoldBalance)) {
                    setprocessState({
                      state: "error",
                      data: "Insufficient balance",
                    });
                  } else {
                    confirmStake();
                  }
                } else {
                  setprocessState({
                    state: "error",
                    data: "Please enter amount",
                  });
                }
              }}
            >
              {btnLoader ? <Spinner size="sm" /> : "Stake"}
            </button>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard bg="dark" />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} bg="dark" />
          ) : (
            <ErrorCard err={processState.data} bg="dark" />
          )}
        </div>
      </div>
      <div
        id="fourthslidecontent3"
        className={`slide-down-content ${activeElementId === "fourthslidecontent3" ? "active" : ""
          }`}
      >
        {
          localStorage.getItem("isoptionaltfa") == "true" ?
            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <div style={{ marginTop: 10 }}>
                <label for="receiver_address" class="form-label">
                  Please enter 2FA Code :
                </label>
                <InputGroup>
                  <Input
                    name="otp"
                    type={"text"}
                    id="otp"
                    value={CLOTP}
                    onChange={(otp) => {
                      setCLOTP(otp.target.value);
                    }}
                    style={{
                      borderTop: "0.489247px solid rgb(222, 226, 230)",
                    }}
                    className='passwordbox'
                  />
                  <InputGroupText
                    className={"inputicon"}
                    onClick={async () => {
                      setCLOTP(await navigator.clipboard.readText());
                    }}
                  >
                    <FaRegPaste color='black' size={22} />
                  </InputGroupText>
                </InputGroup>
              </div>

            </div> : null
        }
        <div class="slidecontent">
          <p class="stakegoldp">
            Collect{" "}
            {props.cmsdata.attributes.projectName == "EGOLD"
              ? props.cmsdata.attributes.projectName
              : props.itemdata.tokname}{" "}
            <img
              src={crossicon}
              alt=""
              class="float-right"
              onClick={() => {
                setprocessState({ state: "...", data: null });
                setActiveElementId(null);
              }}
              style={{ cursor: "pointer" }}
            />
          </p>
          {parseInt(endBlk) < parseInt(currentblockNumber) ? (
            <p className="tilespdel" style={{paddingLeft:"5px"}}>0h:0m:0s</p>
          ) : (
            <div className="unstaketimer">
              <p>You can unstake in : </p>
              <BlockTimer targetBlockNumber={endBlk} />
            </div>
          )}
          <div
            class="selectcurbtnsec"
            style={{ margin: "10px 0px" }}
          >
            <button
              style={{ width: "100%", border: "0px" }}
              class="btn-color-primary"
              disabled={parseInt(endBlk) > parseInt(currentblockNumber)}
              onClick={() => {
                confirmUnStake();
              }}
            >
              {unbtnLoader ? <Spinner size="sm" /> : "Collect & Unstake"}
            </button>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard bg="dark" />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} bg="dark" />
          ) : (
            <ErrorCard err={processState.data} bg="dark" />
          )}
        </div>
      </div>{" "}
    </div>
  );
}
