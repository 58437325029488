import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";

export default function TradeData(props) {
  const [marketstate, setMarketstate] = useState({ data: null, loading: true });
  const [liqstate, setLiqstate] = useState({ data: null, loading: true });
  const [trtype, setTrtype] = useState(0);

  useEffect(() => {
    fetchTableData();
  }, []);

  //To get latest trades on load
  function fetchTableData() {
    const liqurl =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLiq/20";

    //fetch liquidity data
    fetch(liqurl)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) => setLiqstate({ data: newData, loading: false }))
      .catch(function (error) {
        console.log(error);
      });

    const marketurl = "https://egold-marketdata.herokuapp.com/v1/summary/";

    //fetch liquidity data
    fetch(marketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setMarketstate({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //To get latest trades or user transactions trade data on button click

  function handleLtype(x) {
    if (x == 0) {
      setTrtype(0);
      setLiqstate({ loading: true });
      const url = "https://egold-marketdata.herokuapp.com/v1/summary/getLiq/20";
      console.log("fetching");

      //fetch trade data
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) => setLiqstate({ data: newData, loading: false }))
        .catch(function (error) {
          console.log(error);
        });
      localStorage.setItem("ltype", 0);
    } else {
      setTrtype(1);
      setLiqstate({ loading: true });
      const url =
        "https://egold-marketdata.herokuapp.com/v1/summary/getLiq/20/" +
        localStorage.getItem("acct");
      console.log("fetching");

      //fetch trade data
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) => setLiqstate({ data: newData, loading: false }))
        .catch(function (error) {
          console.log(error);
        });
      localStorage.setItem("ltype", 1);
    }
  }

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <div class="detailCard secpadding mb20">
      <div class="volhour mb20">
        <p class="volhourp  mb10">Vol 24 Hours</p>
        <p class="volhourp fw700">
          {marketstate.loading
            ? "......"
            : numFormatter(marketstate.data.pools.totalUsd * 2) + " USD"}
        </p>
      </div>
      <div class="wallettabsec latesttradesec brtlr">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              className={
                trtype
                  ? "nav-link typetabbtn brltb "
                  : "nav-link typetabbtn brltb active "
              }
              onClick={() => handleLtype(0)}
            >
              latest deposit
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              className={
                trtype
                  ? "nav-link typetabbtn brrtb active "
                  : "nav-link typetabbtn brrtb "
              }
              onClick={() => handleLtype(1)}
            >
              Your deposit
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-latestdeposit"
            role="tabpanel"
            aria-labelledby="pills-latestdeposit-tab"
            tabindex="0"
          >
            <div class="latesttradetable table-responsive">
              <table class="table table-bordered text-center">
                <thead>
                  <tr class="darktrbg text-white">
                    <th>Quantity (EGOLD (V1))</th>
                    <th>Quantity (BUSD)</th>
                    <th>LPT</th>
                  </tr>
                </thead>
                <tbody>
                  {liqstate.loading ? (
                    <tr>
                      <td></td>
                      <td><Spinner /></td>
                      <td></td>
                    </tr>
                  ) : liqstate.data.length < 1 ? (
                    <tr>
                      <td></td>
                      <td>NO TRADE DATA</td>
                      <td></td>
                    </tr>
                  ) : (
                    Object.keys(liqstate.data).map((k, i) => {
                      let data = liqstate.data;
                      return (
                        <tr class={data[i].type == -1 ? "lightredbg" : "lightgreenbg"} key={i}
                          onClick={() =>
                            window.open(
                              "https://bscscan.com/tx/" + data[i]._id,
                              "_blank"
                            )
                          }
                          style={{ cursor: "pointer" }}>
                          <td> {data[i].TAG ? parseFloat(data[i].TAG).toFixed(2) : "..."}</td>
                          <td>{data[i].totalUSD ? "$" + parseFloat(data[i].totalUSD).toFixed(3) : "..."}</td>
                          <td>{data[i].LPT ? parseFloat(data[i].LPT).toFixed(3) : "0.00"}</td>
                        </tr>)
                    })
                  )}

                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
