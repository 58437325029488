import React from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUserCog, FaUserPlus } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";

export default function TradePillsMenu(props) {
  let navigate = useNavigate();
  return (
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          id="pills-swap-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-swap"
          type="button"
          role="tab"
          aria-controls="pills-swap"
          class={
            props.activeval == "swap"
              ? "nav-link typetabbtn brltb active "
              : "nav-link typetabbtn brltb"
          }
          aria-selected={props.activeval == "swap" ? "true" : "false"}
          onClick={() => {
            navigate("/trade/swap");
          }}
        >
          Swap
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="pills-liquidity-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-liquidity"
          type="button"
          role="tab"
          aria-controls="pills-liquidity"
          class={
            props.activeval == "liquidity"
              ? "nav-link typetabbtn  active "
              : "nav-link typetabbtn "
          }
          aria-selected={props.activeval == "liquidity" ? "true" : "false"}
          onClick={() => {
            navigate("/trade/liquidity");
          }}
        >
          liquidity
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="pills-lpfarm-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-lpfarm"
          type="button"
          role="tab"
          aria-controls="pills-lpfarm"
          class={
            props.activeval == "lpfarm"
              ? "nav-link typetabbtn brrtb active "
              : "nav-link typetabbtn brrtb"
          }
          aria-selected={props.activeval == "lpfarm" ? "true" : "false"}
          onClick={() => {
            navigate("/trade/lpfarm");
          }}
        >
          LP farm
        </button>
      </li>
    </ul>
  );
}
