import React, { useState, useEffect } from "react";
import dataVal from "../../../data/Abis.json";
import ErrorCard from "../../LoaderCard/ErrorCard";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import { FaRegPaste } from "react-icons/fa6";
import {
  Input,
  InputGroup,
  InputGroupText,
  Spinner
} from "reactstrap";
import { invokeservice } from "../../../api/apiService";
export default function LPAddWidget(props) {
  const Web3 = require("web3");


  const PANCAKE_FACTORY_ABI = dataVal.pancakeFactoryAbi
  const BUSD_ADDRESS = dataVal.busdtokencontract;
  const EGOLD_ADDRESS = dataVal.egoldtokencontract;
  const TOKEN_ABI = dataVal.busdtokenabi;
  const BUSDFARM_ABI = dataVal.lpfarmabi;
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;


  const [liquidityContract, setliquidityContract] = useState("..");
  const [liquidityTokenBlnce, setliquidityTokenBlnce] = useState("0.0000");
  const [addLQamnt, setaddLQamnt] = useState(null);
  const [disableBTN, setdisableBTN] = useState(true);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [submitLoader, setsubmitLoader] = useState(false);
  const [approveLoader, setapproveLoader] = useState(false);
  const [tokenapp, setTokenapp] = useState(false); //token approve button enabled
  const [OTP, setOTP] = useState("");


  useEffect(() => {

    if (localStorage.getItem("acct")) {
      console.log("active");
      fetchLQBalance();
    } else {
      console.log("inactive");
      setliquidityTokenBlnce("0.0000");
      setaddLQamnt("");

    }
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  const fetchLQBalance = async () => {
    if (localStorage.getItem("acct")) {
      const web3 = new Web3(process.env.REACT_APP_RPC);
      const pancakeFactorycontractInstance = await new web3.eth.Contract(
        PANCAKE_FACTORY_ABI,
        PANCAKE_FACTORY_ADDRESS
      );
      console.log("json interface", pancakeFactorycontractInstance)
      await pancakeFactorycontractInstance.methods
        .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
        .call({}, function (e, res) {
          setliquidityContract(res);
          const LQInstance = new web3.eth.Contract(TOKEN_ABI, res);
          LQInstance.methods
            .balanceOf(localStorage.getItem("acct"))
            .call({}, function (e, resbalance) {
              setliquidityTokenBlnce(
                noround(web3.utils.fromWei(resbalance), 4)
              );
            });
        });
    }
  };

  const getOnchangeval = (e) => {
    setaddLQamnt(e);
    // var weiToken = web3.utils.toWei(e, "ether");
  };
  function getMaxF() {
    getOnchangeval(liquidityTokenBlnce);
  }

  const depositLQ = async () => {
    setprocessState({
      state: "...",
      data: null
    })
    if (
      addLQamnt <= liquidityTokenBlnce
    ) {
      if (localStorage.getItem("isoptionaltfa") == "false" || OTP) {
        setprocessState({
          state: "processing",
          data: null
        })
        const token = localStorage.getItem("accessToken");
        const depositLPfarm = {
          job: {
            type: "depositLPfarm",
            params: {
              useragent: navigator.userAgent,
              amount: addLQamnt,
              totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
            },
          },
        };

        var headers = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };
        invokeservice(depositLPfarm, headers).then((json) => {
          if (json.data.status != "failed") {
            setaddLQamnt("")
            setOTP("")
            fetchLQBalance();
            setprocessState({
              state: "done",
              data: json.data.task_id
            });
          } else {
            setaddLQamnt("")
            setOTP("")
            setprocessState({
              state: "error",
              data: json.data.message
            });
          }
        });
      }
    } else {
      setaddLQamnt("")
      setOTP("")
      setprocessState({
        state: "error",
        data: "Insufficient amount"
      });
    }

  };


  return (
    <div class="detailCard secpadding mb20">

      <div class="fs12 mb10" style={{ display: "flex", justifyContent: "space-between" }}>
        <p >LPT Amount

        </p>
        <p>
          Balance : {liquidityTokenBlnce}
        </p>
      </div>


      <div class="sliderinput mb15">
        <input type="text" class="form-control " value={addLQamnt}
          onChange={(e) => getOnchangeval(e.target.value)} />
        <p class="labelusemax" style={{ cursor: "pointer" }} onClick={() => getMaxF()}>Use Max</p>
      </div>
      {
        localStorage.getItem("isoptionaltfa") == "true" ?
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <div style={{ marginTop: 10 }}>
              <label for="receiver_address" class="form-label">
                Please enter 2FA Code :
              </label>
              <InputGroup>
                <Input
                  name="otp"
                  type={"text"}
                  id="otp"
                  value={OTP}
                  onChange={(otp) => {
                    setOTP(otp.target.value);
                  }}
                  style={{
                    borderTop: "0.489247px solid rgb(222, 226, 230)",
                  }}
                  className='passwordbox'
                />
                <InputGroupText
                  className={"inputicon"}
                  onClick={async () => {
                    setOTP(await navigator.clipboard.readText());
                  }}
                >
                  <FaRegPaste color='black' size={22} />
                </InputGroupText>
              </InputGroup>
            </div>

          </div> : null
      }
      <div class="selectcurbtnsec">
        <button class="btn-color-primary" style={{ border: "unset", cursor: "pointer", width: "100%" }} onClick={() => depositLQ()}>
          Deposit
        </button>
      </div >

      {processState.state == "..." ? (
        ""
      ) : processState.state == "processing" ? (
        <LoaderCard />
      ) : processState.state == "done" ? (
        <ConfirmCard tx={processState.data} />
      ) : (
        <ErrorCard err={processState.data} />
      )}



    </div >
  );
}
